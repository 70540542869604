import { Controller } from "@hotwired/stimulus"
import { Sortable } from "@shopify/draggable"

export default class extends Controller {
  static targets = ["template", "categoriesList", "dropzone"]
  static values = {
    id: String,
    name: String
  }

  connect() {
    this.initializeSortable()
    this.initializeMetricSortable()
    this.updateDividers()
    this.dragCounter = 0
  }

  initializeSortable() {
    const sortable = new Sortable(this.categoriesListTarget, {
      draggable: '.category-wrapper',
      handle: '[data-drag-handle]',
      mirror: {
        constrainDimensions: true,
      }
    })

    sortable.on('drag:start', () => {
      this.categoriesListTarget.querySelectorAll('.category-divider').forEach(el => el.remove())
    })

    sortable.on('drag:stop', () => {
      requestAnimationFrame(() => {
        this.updatePositions()
        this.updateDividers()
      })
    })
  }

  initializeMetricSortable() {
    this.element.querySelectorAll('.metrics-dropzone').forEach(container => {
      const dropzoneInner = container.querySelector('.metrics-dropzone-inner')
      if (!dropzoneInner) return

      new Sortable(dropzoneInner, {
        draggable: '.metric-tag',
        handle: '.metric-handle',
        mirror: {
          constrainDimensions: true
        }
      })
    })
  }

  // Drag and Drop for Metrics
  handleDragStart(event) {
    event.currentTarget.classList.add('opacity-50')
    event.dataTransfer.effectAllowed = 'copy'
    const metricId = event.currentTarget.dataset.scorecardMetricCategoryFormIdValue
    const metricName = event.currentTarget.dataset.scorecardMetricCategoryFormNameValue
    event.dataTransfer.setData('metric/id', metricId)
    event.dataTransfer.setData('metric/name', metricName)
  }

  handleDragEnd(event) {
    event.currentTarget.classList.remove('opacity-50')
  }

  handleDragOver(event) {
    event.preventDefault()
  }

  handleDragEnter(event) {
    event.preventDefault()
    this.dragCounter++
    event.currentTarget.classList.add('bg-gray-100')
    event.currentTarget.classList.add('border-primary-300')
  }

  handleDragLeave(event) {
    event.preventDefault()
    this.dragCounter--
    if (this.dragCounter === 0) {
      event.currentTarget.classList.remove('bg-gray-100')
      event.currentTarget.classList.remove('border-primary-300')
    }
  }

  handleDrop(event) {
    event.preventDefault()
    this.dragCounter = 0
    event.currentTarget.classList.remove('border-primary-300')

    const metricId = event.dataTransfer.getData('metric/id')
    const metricName = event.dataTransfer.getData('metric/name')
    const categoryId = event.currentTarget.dataset.categoryId

    // Check if metric already exists in this category
    if (this.hasMetric(event.currentTarget, metricId)) {
      return
    }

    this.addMetricToCategory(event.currentTarget, metricId, metricName)
  }

  removeMetric(event) {
    event.preventDefault()
    const metricTag = event.currentTarget.closest('.metric-tag')
    
    // Check if the container will be empty after removal
    const container = metricTag.closest('.flex-wrap')
    const emptyState = container.closest('.metrics-dropzone').querySelector('.empty-state')
    
    metricTag.remove()
    
    // Show empty state if this was the last metric
    if (container.children.length === 0 && emptyState) {
      emptyState.style.display = 'block'
    }
  }

  hasMetric(dropzone, metricId) {
    return dropzone.querySelector(`[data-metric-id="${metricId}"]`) !== null
  }

  addMetricToCategory(dropzone, metricId, metricName) {
    const metricTag = document.createElement('div')
    metricTag.className = 'metric-tag bg-white px-4 py-2 rounded-md border border-gray-200 shadow-xs flex items-center justify-between w-full group hover:bg-gray-50'
    metricTag.dataset.metricId = metricId

    // Get the category's index from the nested-fields container
    const nestedFields = dropzone.closest('.nested-fields')
    const categoryIndex = nestedFields.dataset.index

    metricTag.innerHTML = `
      <div class="flex items-center gap-3">
        <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 text-gray-400 shrink-0 metric-handle cursor-move"><circle cx="9" cy="12" r="1"></circle><circle cx="9" cy="5" r="1"></circle><circle cx="9" cy="19" r="1"></circle><circle cx="15" cy="12" r="1"></circle><circle cx="15" cy="5" r="1"></circle><circle cx="15" cy="19" r="1"></circle></svg>
        <span class="text-sm text-gray-700">${metricName}</span>
      </div>
      <button type="button" class="text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity" data-action="scorecard-metric-category-form#removeMetric">
        <svg class="w-4 h-4 shrink-0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M18 6L6 18M6 6l12 12"/>
        </svg>
      </button>
      <input type="hidden" name="scorecard[scorecard_metric_categories_attributes][${categoryIndex}][metric_ids][]" value="${metricId}">
    `

    const tagsContainer = dropzone.querySelector('.metrics-dropzone-inner')
    tagsContainer.appendChild(metricTag)

    const emptyState = dropzone.querySelector('.empty-state')
    if (emptyState) {
      emptyState.style.display = 'none'
    }
  }

  addCategory(event) {
    const template = this.templateTarget.innerHTML
    const newIndex = this.getNextIndex()
    const newContent = template.replace(/INDEX/g, newIndex)
    
    const temp = document.createElement('div')
    temp.innerHTML = newContent
    const newWrapper = temp.firstElementChild

    // Find insertion point - either after the clicked divider or at the end of the list
    const divider = event.currentTarget.closest('.category-divider')
    if (divider) {
      divider.insertAdjacentElement('beforebegin', newWrapper)
    } else {
      this.categoriesListTarget.appendChild(newWrapper)
    }
    
    // Initialize Sortable for the new category's metrics container
    const dropzoneInner = newWrapper.querySelector('.metrics-dropzone-inner')
    if (dropzoneInner) {
      new Sortable(dropzoneInner, {
        draggable: '.metric-tag',
        handle: '.metric-handle',
        mirror: {
          constrainDimensions: true
        }
      })
    }
    
    this.updatePositions()
    this.updateDividers()
  }

  removeCategory(event) {
    const wrapper = event.target.closest('.category-wrapper')
    const nestedFields = wrapper.querySelector('.nested-fields')
    
    if (nestedFields.dataset.newRecord === "true") {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'
      const destroyInput = nestedFields.querySelector("input[name*='_destroy']")
      if (destroyInput) destroyInput.value = "1"
    }
    
    this.updatePositions()
    this.updateDividers()
  }

  updateDividers() {
    // Remove all existing dividers
    this.categoriesListTarget.querySelectorAll('.category-divider').forEach(el => el.remove())

    // Get all visible category wrappers
    const wrappers = Array.from(this.categoriesListTarget.querySelectorAll('.category-wrapper'))
      .filter(wrapper => wrapper.style.display !== 'none')

    // Add divider after each wrapper except the last one
    wrappers.forEach((wrapper, index) => {
      if (index < wrappers.length) {
        const divider = this.createDivider(index)
        wrapper.insertAdjacentElement('afterend', divider)
      }
    })
  }

  createDivider(position) {
    const divider = document.createElement('div')
    divider.className = 'category-divider inline-flex items-center justify-center w-full relative'
    divider.innerHTML = `
      <hr class="w-full h-1 my-8 bg-gray-200 border-0 rounded-sm dark:bg-gray-700">
      <div class="absolute px-4 -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
        <button type="button" data-action="click->scorecard-metric-category-form#addCategory" data-position="${position}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-6 h-6 text-gray-700 hover:text-primary-600 dark:text-gray-300">
            <circle cx="12" cy="12" r="10"/>
            <path d="M12 8v8"/>
            <path d="M8 12h8"/>
          </svg>
        </button>
      </div>
    `
    return divider
  }

  getNextIndex() {
    const existingIndices = Array.from(this.categoriesListTarget.querySelectorAll('.category-wrapper'))
      .map(wrapper => parseInt(wrapper.dataset.categoryId))
    return Math.max(...existingIndices, -1) + 1
  }

  updatePositions() {
    const wrappers = Array.from(this.categoriesListTarget.querySelectorAll('.category-wrapper'))
      .filter(wrapper => wrapper.style.display !== 'none')
    
    wrappers.forEach((wrapper, index) => {
      const positionInput = wrapper.querySelector('input[name*="[position]"]')
      if (positionInput) positionInput.value = index

      const button = wrapper.querySelector('button[data-action="click->scorecard-metric-category-form#addCategory"]')
      if (button) {
        button.dataset.position = index
      }
    })
  }
}