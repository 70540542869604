// app/javascript/controllers/data_retrieval_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "jsonOutput",       // Hidden field to store the final JSON
    "typeSelect",       // Select field for query type
    "fieldsContainer",  // Container for the dynamic fields
    "addFilterButton",  // Button to add a new filter
    "filtersContainer", // Container for filter fields
    "form",             // The form itself for listening to changes
    "jsonPreview"       // Pre element for displaying JSON preview
  ]

  connect() {
    this.isInitializing = false
    this.isAddingFilter = false
    this.isRemovingFilter = false
    this.boundAddFilter = null  // Store bound function for cleanup
    
    // Parse initial JSON if it exists
    this.initializeFromJSON()

    // Set up form change listener
    const updateJSONHandler = this.updateJSON.bind(this)
    this.formTarget.addEventListener("change", (e) => {
      // Only update if not initializing
      if (!this.isInitializing) {
        updateJSONHandler(e)
      }
    })
    
    this.formTarget.addEventListener("input", (e) => {
      // Only update if not initializing
      if (!this.isInitializing) {
        updateJSONHandler(e)
      }
    })
  }

  initializeFromJSON() {
    // Get initial JSON value if it exists
    const jsonField = this.jsonOutputTarget
    
    if (jsonField.value) {
      try {
        // Set initialization state before doing anything
        this.isInitializing = true
        const config = JSON.parse(jsonField.value)

        // Set the query type first
        if (config.type) {
          this.typeSelectTarget.value = config.type
        }

        // Render the fields for the selected type - this won't add default filters
        // because isInitializing is true
        this.renderFieldsForType(config.type)

        // Now populate all fields including filters - this uses a direct HTML approach for filters
        this.populateFieldsFromJSON(config)
        
        // Now that all fields are populated, update the JSON preview
        if (this.hasJsonPreviewTarget) {
          this.jsonPreviewTarget.textContent = JSON.stringify(config, null, 2)
        }
        
        // Set initialization to false after a delay to ensure any pending operations complete
        setTimeout(() => {
          this.isInitializing = false
        }, 300)
      } catch (e) {
        console.error("Error initializing from JSON:", e)
        // In case of error, ensure we have default fields
        this.renderFieldsForType(this.typeSelectTarget.value)
        this.isInitializing = false
      }
    } else {
      // If no initial value, just render fields for the default type
      this.renderFieldsForType(this.typeSelectTarget.value)
    }
  }

  populateFieldsFromJSON(config) {
    // Populate common fields
    this.setFieldValue("table", config.table || "")
    this.setFieldValue("date_dimension", config.date_dimension || "")
    this.setFieldValue("time_format", config.time_format || "timestamp")

    // Populate type-specific fields
    const type = config.type

    if (type === "latest_value") {
      this.setFieldValue("value_column", config.value_column || "")
    }
    else if (type === "count_with_filter") {
      // Handle filters - completely rewritten
      if (config.filters && typeof config.filters === "object") {
        const filterEntries = Object.entries(config.filters)
        
        // If we have filters, create them directly without using addFilter()
        if (filterEntries.length > 0) {
          let filtersHtml = '';
          
          // Create HTML for all filters at once
          filterEntries.forEach(([key, value], index) => {
            filtersHtml += `
              <div class="filter-row flex items-center gap-2">
                <input type="text" name="data_retrieval[filters][${index}][key]" placeholder="Field"
                       class="shadow-xs focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                       value="${key}">
                <input type="text" name="data_retrieval[filters][${index}][value]" placeholder="Value"
                       class="shadow-xs focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                       value="${this.formatFilterValue(value)}">
                <button type="button" class="remove-filter-btn p-2 text-red-500 hover:text-red-700">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            `;
          });
          
          // Set the HTML directly
          this.filtersContainerTarget.innerHTML = filtersHtml;
          
          // Set up the event listeners for the remove buttons
          this.setupRemoveFilterButtons();
        } else {
          // If no filters in config, add an empty one
          this.addFilter();
        }
      } else {
        // If no filters in config, add an empty one
        this.addFilter();
      }
    }
    else if (type === "sum_daily") {
      this.setFieldValue("value_column", config.value_column || "")
    }
    else if (type === "custom") {
      this.setFieldValue("custom_query", config.custom_query || "")
    }
  }

  setFieldValue(name, value) {
    // Try both possible field name formats
    let field = this.fieldsContainerTarget.querySelector(`[name="data_retrieval[${name}]"]`)
    
    // If not found, try alternative format
    if (!field) {
      field = this.fieldsContainerTarget.querySelector(`[name="${name}"]`)
    }
    
    if (field) {
      field.value = value
    }
  }

  changeQueryType() {
    const type = this.typeSelectTarget.value
    this.renderFieldsForType(type)
    
    // Only update JSON if not initializing to prevent feedback loops
    if (!this.isInitializing) {
      this.updateJSON()
    }
  }

  renderFieldsForType(type) {
    // Clear existing fields
    this.fieldsContainerTarget.innerHTML = ""

    // Add common fields for all types
    let html = `
      <div class="sm:col-span-3 mb-4">
        <label for="data_retrieval_table" class="block text-sm font-medium text-gray-700">Table Name</label>
        <input type="text" name="data_retrieval[table]" id="data_retrieval_table"
               class="mt-1 shadow-xs focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md">
        <p class="mt-1 text-xs text-gray-500">The BigQuery table name</p>
      </div>
      
      <div class="sm:col-span-3 mb-4">
        <label for="data_retrieval_date_dimension" class="block text-sm font-medium text-gray-700">Date Dimension</label>
        <input type="text" name="data_retrieval[date_dimension]" id="data_retrieval_date_dimension"
               class="mt-1 shadow-xs focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md">
        <p class="mt-1 text-xs text-gray-500">The column that contains date information</p>
      </div>
    `

    // Add type-specific fields
    if (type === "latest_value") {
      html += `
        <div class="sm:col-span-3 mb-4">
          <label for="data_retrieval_value_column" class="block text-sm font-medium text-gray-700">Value Column</label>
          <input type="text" name="data_retrieval[value_column]" id="data_retrieval_value_column"
                 class="mt-1 shadow-xs focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md">
          <p class="mt-1 text-xs text-gray-500">The column that contains the metric value</p>
        </div>
      `
    }
    else if (type === "count_with_filter") {
      html += `
        <div class="sm:col-span-6 mb-4">
          <label class="block text-sm font-medium text-gray-700 mb-2">Filters</label>
          <p class="mb-2 text-xs text-gray-500">Add one or more filters to count records</p>
          <div data-data-retrieval-form-target="filtersContainer" class="space-y-2">
            <!-- No default empty filter row - we'll add them programmatically -->
          </div>
          <button type="button" id="add-filter-btn" data-data-retrieval-form-target="addFilterButton" 
                  class="mt-2 inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-xs text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
            </svg>
            Add Filter
          </button>
        </div>
      `
    }
    else if (type === "sum_daily") {
      html += `
        <div class="sm:col-span-3 mb-4">
          <label for="data_retrieval_value_column" class="block text-sm font-medium text-gray-700">Value Column</label>
          <input type="text" name="data_retrieval[value_column]" id="data_retrieval_value_column"
                 class="mt-1 shadow-xs focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md">
          <p class="mt-1 text-xs text-gray-500">The column that contains the metric value</p>
        </div>
      `
    }
    else if (type === "custom") {
      html += `
        <div class="sm:col-span-6 mb-4">
          <label for="data_retrieval_custom_query" class="block text-sm font-medium text-gray-700">Custom SQL Query</label>
          <textarea name="data_retrieval[custom_query]" id="data_retrieval_custom_query" rows="10"
                 class="mt-1 shadow-xs focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md font-mono"></textarea>
          
          <div class="mt-3 p-3 bg-gray-50 rounded-md border border-gray-200">
            <h4 class="font-medium text-sm text-gray-800 mb-2">Available Placeholders:</h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div>
                <h5 class="font-medium text-xs text-gray-700">Table References:</h5>
                <ul class="list-disc list-inside mt-1 text-xs text-gray-600">
                  <li><code class="bg-gray-100 px-1 rounded-sm">{{full_table_path}}</code> - Complete table path (dataset_accountid.table)</li>
                  <li><code class="bg-gray-100 px-1 rounded-sm">{{table}}</code> - The table name specified above</li>
                </ul>
              </div>
              <div>
                <h5 class="font-medium text-xs text-gray-700">Date References:</h5>
                <ul class="list-disc list-inside mt-1 text-xs text-gray-600">
                  <li><code class="bg-gray-100 px-1 rounded-sm">{{start_time}}</code> - Start time of current week</li>
                  <li><code class="bg-gray-100 px-1 rounded-sm">{{end_time}}</code> - End time of current week</li>
                  <li><code class="bg-gray-100 px-1 rounded-sm">{{prev_week_start}}</code> - Start time of previous week</li>
                  <li><code class="bg-gray-100 px-1 rounded-sm">{{prev_week_end}}</code> - End time of previous week</li>
                  <li><code class="bg-gray-100 px-1 rounded-sm">{{date_dimension}}</code> - Date column specified above</li>
                </ul>
              </div>
            </div>
            <p class="mt-3 text-xs text-gray-600 font-medium">Your query must return a column named "value" that will be used as the metric value.</p>
          </div>
        </div>
      `
    }

    // Add time format field for all types
    html += `
      <div class="sm:col-span-3 mb-4">
        <label for="data_retrieval_time_format" class="block text-sm font-medium text-gray-700">Time Format</label>
        <select name="data_retrieval[time_format]" id="data_retrieval_time_format"
                class="mt-1 shadow-xs focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md">
          <option value="timestamp">Timestamp</option>
          <option value="date_only">Date Only</option>
        </select>
        <p class="mt-1 text-xs text-gray-500">Format of the date/time field</p>
      </div>
    `
    
    // Add a hidden filtersContainer if one isn't already included
    // This prevents errors when type isn't count_with_filter
    if (type !== "count_with_filter") {
      html += `<div data-data-retrieval-form-target="filtersContainer" class="hidden"></div>`
    }

    this.fieldsContainerTarget.innerHTML = html
    
    // Set up event listeners manually
    this.setupEventListeners(type);
    
    // If we're count_with_filter type and not initializing, add a default empty filter
    if (type === "count_with_filter" && !this.isInitializing) {
      this.addFilter();
    }
  }

  setupEventListeners(type) {
    // Set up add filter button if available
    if (type === "count_with_filter" && this.hasAddFilterButtonTarget) {
      // Use a single event listener and store it
      if (this.boundAddFilter) {
        this.addFilterButtonTarget.removeEventListener('click', this.boundAddFilter)
      }
      this.boundAddFilter = this.handleAddFilterClick.bind(this)
      this.addFilterButtonTarget.addEventListener('click', this.boundAddFilter)
      
      // Set up remove filter buttons
      this.setupRemoveFilterButtons()
    }
  }
  
  setupRemoveFilterButtons() {
    // Get all remove filter buttons and attach event listeners
    const removeButtons = this.element.querySelectorAll('.remove-filter-btn')
    removeButtons.forEach(button => {
      button.addEventListener('click', this.handleRemoveFilterClick.bind(this))
    })
  }
  
  handleAddFilterClick(event) {
    this.addFilter(event)
  }
  
  handleRemoveFilterClick(event) {
    this.removeFilter(event)
  }

  addFilter(event) {
    if (event) {
      // Ensure we stop the event from triggering multiple times
      event.preventDefault()
      event.stopPropagation()
    }

    // Check if we're already processing an add filter action to prevent duplicates
    if (this.isAddingFilter) return;
    
    // Check if the filtersContainer exists
    if (!this.hasFiltersContainerTarget) {
      return;
    }
    
    try {
      this.isAddingFilter = true;
      
      const filtersContainer = this.filtersContainerTarget
      const filterCount = filtersContainer.querySelectorAll('.filter-row').length

      const filterRow = document.createElement('div')
      filterRow.className = 'filter-row flex items-center gap-2'
      filterRow.innerHTML = `
        <input type="text" name="data_retrieval[filters][${filterCount}][key]" placeholder="Field"
               class="shadow-xs focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md">
        <input type="text" name="data_retrieval[filters][${filterCount}][value]" placeholder="Value"
               class="shadow-xs focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md">
        <button type="button" class="remove-filter-btn p-2 text-red-500 hover:text-red-700">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
          </svg>
        </button>
      `

      filtersContainer.appendChild(filterRow)
      
      // Add event listener to the new remove button
      const removeButton = filterRow.querySelector('.remove-filter-btn')
      removeButton.addEventListener('click', this.handleRemoveFilterClick.bind(this))

      // Only update the JSON if not in initialization mode
      if (!this.isInitializing) {
        this.updateJSON()
      }
    } finally {
      // Always reset the flag after we're done
      setTimeout(() => {
        this.isAddingFilter = false;
      }, 100);
    }
  }

  removeFilter(event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    // Prevent multiple rapid removals
    if (this.isRemovingFilter) return;
    
    try {
      this.isRemovingFilter = true;
      
      const button = event.currentTarget
      const filterRow = button.closest('.filter-row')

      if (filterRow) {
        filterRow.remove()
        this.updateJSON()
      }
    } finally {
      setTimeout(() => {
        this.isRemovingFilter = false;
      }, 100);
    }
  }

  updateJSON() {
    // Skip updates during initialization to prevent feedback loops
    if (this.isInitializing) {
      return
    }

    const type = this.typeSelectTarget.value
    const config = { type }

    // Get common field values
    config.table = this.getFieldValue("table")
    config.date_dimension = this.getFieldValue("date_dimension")
    config.time_format = this.getFieldValue("time_format")

    // Get type-specific field values
    if (type === "latest_value") {
      config.value_column = this.getFieldValue("value_column")
    }
    else if (type === "count_with_filter") {
      // Only try to get filters if the container exists
      if (this.hasFiltersContainerTarget) {
        config.filters = this.getFilters()
      } else {
        config.filters = {}
      }
    }
    else if (type === "sum_daily") {
      config.value_column = this.getFieldValue("value_column")
    }
    else if (type === "custom") {
      config.custom_query = this.getFieldValue("custom_query")
    }

    // Update the hidden JSON field
    this.jsonOutputTarget.value = JSON.stringify(config)

    // Update the JSON preview
    if (this.hasJsonPreviewTarget) {
      this.jsonPreviewTarget.textContent = JSON.stringify(config, null, 2)
    }

    // Dispatch an event to notify parent controllers about the change
    this.dispatch("update", { detail: { config } })
  }

  getFieldValue(name) {
    const field = this.fieldsContainerTarget.querySelector(`[name="data_retrieval[${name}]"]`)
    return field ? field.value : ""
  }

  getFilters() {
    // Safety check to prevent errors
    if (!this.hasFiltersContainerTarget) return {}
    
    const filters = {}
    const filterRows = this.filtersContainerTarget.querySelectorAll(".filter-row")

    filterRows.forEach(row => {
      const keyInput = row.querySelector("input[name$='[key]']")
      const valueInput = row.querySelector("input[name$='[value]']")

      if (keyInput && valueInput && keyInput.value) {
        // Special handling for IS NULL and IS NOT NULL
        if (valueInput.value.toUpperCase() === "IS NULL") {
          filters[keyInput.value] = null
        } else if (valueInput.value.toUpperCase() === "IS NOT NULL") {
          filters[keyInput.value] = "IS NOT NULL"
        } else if (valueInput.value.toLowerCase() === "null") {
          filters[keyInput.value] = null
        } else {
          // Keep all other values (including "true" and "false") as strings
          filters[keyInput.value] = valueInput.value
        }
      }
    })

    return filters
  }

  // Add a new validation method for form fields
  validateForm() {
    // Get all the required fields for the current type
    const requiredFields = this.getRequiredFieldsForType(this.typeSelectTarget.value)
    let isValid = true

    // Check each required field
    requiredFields.forEach(fieldName => {
      const element = this.fieldsContainerTarget.querySelector(`[name="data_retrieval[${fieldName}]"]`)
      if (element) {
        const value = element.value.trim()
        const isFieldValid = value !== ''

        // We're now just tracking validity without visual indicators
        isValid = isValid && isFieldValid
      }
    })

    // For filter type, validate that at least one filter exists and is not empty
    if (this.typeSelectTarget.value === 'count_with_filter') {
      const filterRows = this.filtersContainerTarget.querySelectorAll('.filter-row')
      let areFiltersValid = filterRows.length > 0

      // Validate each filter row
      filterRows.forEach(row => {
        const keyInput = row.querySelector('[name$="[key]"]')
        const valueInput = row.querySelector('[name$="[value]"]')

        if (keyInput && valueInput) {
          const keyValid = keyInput.value.trim() !== ''
          const valueValid = valueInput.value.trim() !== ''

          areFiltersValid = areFiltersValid && keyValid && valueValid
        }
      })

      isValid = isValid && areFiltersValid
    }

    return isValid
  }

  // Helper method to get required fields based on query type
  getRequiredFieldsForType(type) {
    const commonFields = ['table', 'date_dimension', 'time_format']

    switch (type) {
      case 'latest_value':
        return [...commonFields, 'value_column']
      case 'count_with_filter':
        return [...commonFields] // Filters handled separately
      case 'sum_daily':
        return [...commonFields, 'value_column']
      case 'custom':
        return [...commonFields, 'custom_query']
      default:
        return commonFields
    }
  }

  // Helper to format filter values for the HTML
  formatFilterValue(value) {
    if (value === null) {
      return "null";
    } else if (value === "IS NOT NULL") {
      return "IS NOT NULL";
    } else if (value === true) {
      return "true";
    } else if (value === false) {
      return "false";
    } else {
      return String(value);
    }
  }
}