import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "icon"]

  connect() {
    // Start expanded by default for better UX when adding new collections
    if (this.element.dataset.newRecord === "true") {
      this.expand()
    } else {
      this.collapse()
    }
  }

  toggle() {
    if (this.contentTarget.classList.contains("hidden")) {
      this.expand()
    } else {
      this.collapse()
    }
  }

  expand() {
    this.contentTarget.classList.remove("hidden")
    this.iconTarget.classList.remove("rotate-180")
  }

  collapse() {
    this.contentTarget.classList.add("hidden")
    this.iconTarget.classList.add("rotate-180")
  }
} 