import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["searchInput", "table"]

  connect() {
    this.timeout = null
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const query = this.searchInputTarget.value
      const currentUrl = new URL(window.location)

      if (query) {
        currentUrl.searchParams.set('query', query)
      } else {
        currentUrl.searchParams.delete('query')
      }

      // Update URL without reloading page
      window.history.pushState({}, '', currentUrl)

      fetch(currentUrl, {
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => response.json())
        .then(data => {
          this.tableTarget.innerHTML = data.accounts
        })
    }, 300)
  }
} 