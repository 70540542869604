import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["formula"]

  connect() {
    if (this.hasFormulaTarget) {
      this.formatFormula()
    }
  }

  formatFormula() {
    const formula = this.formulaTarget.textContent
    const cleanedFormula = this.cleanFormula(formula)
    this.formulaTarget.textContent = cleanedFormula
  }

  cleanFormula(formula) {
    // Replace each metric placeholder with its cleaned name
    return formula.replace(/{([^:]+):[^}]+}/g, (match, metricName) => {
      // Handle both dash and underscore separators
      const parts = metricName
        .split(/[-_]/)  // Split by either dash or underscore
        .map(part => {
          const word = part.trim()
          // If it's a 2-letter word, make it uppercase
          if (word.length === 2) {
            return word.toUpperCase()
          }
          // Otherwise capitalize first letter
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        });

      // Join with spaces
      return parts.join(' ');
    });
  }
}