import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "container"]

  connect() {}

  add(event) {
    event.preventDefault()
    const businessAreaId = event.currentTarget.dataset.businessAreaId
    
    try {
      const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
      
      const wrapper = document.createElement('div')
      wrapper.innerHTML = content

      // If a business area ID is provided, set it in the hidden field
      if (businessAreaId) {
        const hiddenField = wrapper.querySelector('input[name*="[core_business_area_id]"]')
        if (hiddenField) {
          hiddenField.value = businessAreaId
        }
      }

      // Insert into the container target
      this.containerTarget.insertAdjacentHTML('beforeend', wrapper.innerHTML)
    } catch (error) {
      console.error("Error in add category:", error)
    }
  }

  remove(event) {
    event.preventDefault()
    const wrapper = event.target.closest('.nested-fields')
    
    // New records can be removed from the dom
    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove()
    // Existing records are hidden and marked for deletion
    } else {
      wrapper.style.display = "none"
      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = "1"
    }
  }
} 