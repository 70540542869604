import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "select", "clearButton"]

  connect() {
    this.boundHandleStream = this.handleStream.bind(this)
    document.addEventListener('turbo:before-stream-render', this.boundHandleStream)
  }

  disconnect() {
    document.removeEventListener('turbo:before-stream-render', this.boundHandleStream)
  }

  clearSupervisor(event) {
    if (this.selectTarget) {
      this.selectTarget.value = ''
    }
    if (this.clearButtonTarget) {
      this.clearButtonTarget.style.display = 'none'
    }
  }

  handleSubmit(event) {
    const orgChartController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("[data-controller='org-chart']"),
      "org-chart"
    )

    if (orgChartController && orgChartController.contentTarget) {
      try {
        const state = {
          transform: orgChartController.contentTarget.getAttribute('transform'),
          scale: orgChartController.scale || 0.7,
          panX: orgChartController.panX || 0,
          panY: orgChartController.panY || 0,
          expandedNodes: orgChartController.expandedNodes ?
                        Array.from(orgChartController.expandedNodes) : []
        }

        sessionStorage.setItem('orgChartState', JSON.stringify(state))
      } catch (error) {
      }
    }

    const formData = new FormData(event.detail.formSubmission.formElement)
    // Check if this is a head-related change
    if (formData.get('organization_relationship[is_head]') === 'true' ||
        this.isHeadRelatedChange(formData)) {
      this.refreshTeamMembers()
    }
  }

  isHeadRelatedChange(formData) {
    // Check if this is a change involving the current head
    const headNode = document.querySelector('.node[data-is-head="true"]')
    if (headNode) {
      const headId = headNode.dataset.id
      return formData.get('organization_relationship[account_user_id]') === headId ||
             formData.get('organization_relationship[supervised_by_id]') === headId
    }
    return false
  }

  refreshTeamMembers() {
    // Get the current search query from the URL
    const currentUrl = new URL(window.location.href)
    const searchQuery = currentUrl.searchParams.get('query')

    // Build the new URL with the search query if it exists
    let refreshUrl = window.location.pathname
    if (searchQuery) {
      refreshUrl += `?query=${encodeURIComponent(searchQuery)}`
    }

    Turbo.visit(refreshUrl, {
      frame: 'team-members',
      action: 'replace'
    })
  }

  handleStream(event) {
    try {
      const savedState = sessionStorage.getItem('orgChartState')
      if (!savedState) {
        return
      }

      const state = JSON.parse(savedState)

      setTimeout(() => {
        const orgChartController = this.application.getControllerForElementAndIdentifier(
          document.querySelector("[data-controller='org-chart']"),
          "org-chart"
        )

        if (!orgChartController) {
          return
        }

        const newContentElement = document.querySelector('[data-org-chart-target="content"]')
        if (newContentElement) {
          // Create a new SVG namespace element
          const svg = document.createElementNS("http://www.w3.org/2000/svg", "g")
          Array.from(newContentElement.attributes).forEach(attr => {
            svg.setAttribute(attr.name, attr.value)
          })
          svg.innerHTML = newContentElement.innerHTML
          newContentElement.parentNode.replaceChild(svg, newContentElement)

          // Restore controller state
          orgChartController.scale = state.scale
          orgChartController.panX = state.panX
          orgChartController.panY = state.panY
          orgChartController.expandedNodes = new Set(state.expandedNodes)

          requestAnimationFrame(() => {
            svg.setAttribute('transform',
              `translate(${state.panX + 0.1}, ${state.panY}) scale(${state.scale})`
            )

            svg.getBoundingClientRect() // Force reflow

            requestAnimationFrame(() => {
              svg.setAttribute('transform', state.transform)

              // Expand nodes starting from head while respecting expanded states
              const expandNodesFromHead = (startNode) => {
                if (!startNode) return

                const nodeId = startNode.dataset.id
                if (state.expandedNodes.includes(nodeId)) {
                  orgChartController.expandChildren(nodeId)
                  const buttonGroup = orgChartController.element.querySelector(
                    `.expand-button-group[data-node-id="${nodeId}"]`
                  )
                  if (buttonGroup) {
                    const buttonText = buttonGroup.querySelector('.expand-button-text')
                    if (buttonText) buttonText.textContent = '-'
                  }

                  // Process children recursively
                  const children = orgChartController.element.querySelectorAll(
                    `.node[data-parent-id="${nodeId}"]`
                  )
                  children.forEach(child => expandNodesFromHead(child))
                }
              }

              // Start expansion from the head node
              const headNode = orgChartController.element.querySelector('.node[data-is-head="true"]')
              if (headNode) {
                expandNodesFromHead(headNode)
              }

              orgChartController.updateLayout()
              orgChartController.drawConnections()
            })
          })
        }

        // Clean up
        sessionStorage.removeItem('orgChartState')
      }, 100)
    } catch (error) {
    }
  }
}