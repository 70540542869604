import { Controller } from "@hotwired/stimulus"
import { initFlowbite } from 'flowbite'

export default class extends Controller {
  static targets = ["searchInput", "table", "pagination"]

  connect() {
    // Set initial value from URL params if any
    const urlParams = new URLSearchParams(window.location.search)
    this.searchInputTarget.value = urlParams.get('query') || ''

    // Handle pagination clicks
    this.paginationTarget.addEventListener('click', (event) => {
      const link = event.target.closest('a')
      if (link) {
        event.preventDefault()
        this.fetchPage(link.href)
      }
    })
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const searchTerm = this.searchInputTarget.value
      const currentUrl = new URL(window.location)

      if (searchTerm) {
        currentUrl.searchParams.set('query', searchTerm)
        currentUrl.searchParams.set('page', '1')
      } else {
        currentUrl.searchParams.delete('query')
        currentUrl.searchParams.set('page', '1')
      }

      this.fetchPage(currentUrl.toString())
    }, 300)
  }

  async fetchPage(url) {
    try {
      // Show loading state
      this.tableTarget.classList.add('opacity-50')
      this.paginationTarget.classList.add('opacity-50')

      // Update URL without reloading page
      window.history.pushState({}, '', url)

      const response = await fetch(url, {
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })

      if (!response.ok) throw new Error('Network response was not ok')

      const data = await response.json()

      // Update the table content
      this.tableTarget.innerHTML = data.users

      // Update pagination if it exists
      if (this.hasPaginationTarget) {
        this.paginationTarget.innerHTML = await this.renderPagination(data.pagination)
      }

      // Reinitialize Flowbite components
      initFlowbite()
    } catch (error) {
      console.error('Error:', error)
    } finally {
      // Remove loading state
      this.tableTarget.classList.remove('opacity-50')
      this.paginationTarget.classList.remove('opacity-50')
    }
  }

  renderPagination(pagination) {
    if (!pagination || pagination.pages <= 1) return ''

    const currentUrl = new URL(window.location)

    const prevLink = pagination.prev_page ? this.renderPaginationLink(currentUrl, pagination.prev_page, 'Previous') : ''
    const nextLink = pagination.next_page ? this.renderPaginationLink(currentUrl, pagination.next_page, 'Next') : ''

    return `
      <nav class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-6" aria-label="Pagination">
        <div class="hidden sm:block">
          <p class="text-sm text-gray-700">
            Showing <span class="font-medium">${(pagination.page - 1) * pagination.items_per_page + 1}</span>
            to <span class="font-medium">${Math.min(pagination.page * pagination.items_per_page, pagination.count)}</span>
            of <span class="font-medium">${pagination.count}</span> results
          </p>
        </div>
        <div class="flex flex-1 justify-between sm:justify-end space-x-3">
          ${prevLink}
          ${nextLink}
        </div>
      </nav>
    `
  }

  renderPaginationLink(url, page, text) {
    const newUrl = new URL(url)
    newUrl.searchParams.set('page', page)

    return `
      <a href="${newUrl.toString()}" 
         class="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        ${text}
      </a>
    `
  }
} 