import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "display"]

  connect() {
    console.log("CollectionNameController connected")
    this.updateDisplay()
  }

  updateDisplay() {
    const name = this.inputTarget.value.trim()
    this.displayTarget.textContent = name || "Untitled Collection"
  }
} 