import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  connect() {
    if (this.isTurboPreview()) {
      return;
    }
    this.fetchChartData().then(data => {
      this.initializeChartWithData(data)
    })
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  async fetchChartData() {
    try {
      const metricId = this.element.dataset.trailingSixWeeksChartMetricId
      const url = new URL('/charts/trailing_six_weeks', window.location.origin)
      if (metricId) {
        url.searchParams.append('metric_id', metricId)
      }
      const response = await fetch(url)
      return await response.json();
    } catch (error) {
      console.error('Error fetching chart data:', error)
    }
  }

  formatMetricValue(value, metric = null) {
    if (value === null || value === undefined) return 'N/A';
    if (value === Infinity || value === "Infinity" || value === "∞") return "∞";

    // Convert string values to numbers
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;

    // Handle percentage formatting
    if (metric && (metric.data_type === 'percentage' ||
      (metric.data_type === 'calculated' && metric.number_type === 'percentage'))) {
      return numericValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) + '%';
    }

    // Handle currency formatting
    if (metric && metric.number_type === 'currency') {
      return '$' + numericValue.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    }

    // For non-percentage and non-currency values, only show decimals if they exist
    const hasDecimals = numericValue % 1 !== 0;
    let formattedValue = numericValue.toLocaleString(undefined, {
      minimumFractionDigits: hasDecimals ? 2 : 0,
      maximumFractionDigits: hasDecimals ? 2 : 0
    });

    return formattedValue;
  }

  initializeChartWithData(data) {
    const formattedDates = data.dates
    const options = {
      chart: {
        height: "100%",
        maxWidth: "100%",
        type: "area",
        fontFamily: "Inter, sans-serif",
        dropShadow: { enabled: false },
        toolbar: { show: false },
        padding: { left: 12, right: 12, top: 0, bottom: 0 },
        zoom: { enabled: false },
        background: '#ffffff',
      },
      tooltip: {
        enabled: true,
        theme: false,
        style: {
          fontSize: '12px',
          fontFamily: 'Inter, sans-serif',
        },
        shared: true,
        intersect: false,
        x: {
          show: true,
          formatter: function (val, opts) {
            return formattedDates[opts.dataPointIndex];
          }
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return seriesName + ":";
            }
          },
          formatter: (value) => {
            return this.formatMetricValue(value, data.metric)
          }
        },
        custom: undefined,
        background: {
          enabled: true,
          foreColor: '#ffffff',
          backgroundColor: '#18181b',
          borderColor: '#27272a',
          borderRadius: 6,
          opacity: 1,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 2,
            blur: 3,
            color: '#000000',
            opacity: 0.2
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 0.3,
          opacityTo: 0.1,
          stops: [0, 100],
          shade: "#0ea5e9",
          gradientToColors: ["#0ea5e9"],
        },
      },
      dataLabels: { enabled: false },
      stroke: {
        width: [2, 0],
        curve: 'smooth',
        dashArray: [0, 0],
      },
      markers: {
        size: [6, 4],
        strokeWidth: [0, 0],
        strokeColors: ['#0ea5e9', '#22c55e'],
        shape: ['circle', 'triangle'],
        hover: {
          size: 6,
          sizeOffset: 3
        },
      },
      grid: {
        show: true,
        borderColor: '#e2e8f0',
        strokeDashArray: 5,
        padding: { left: 5, right: 5, top: 0 },
        position: 'back',
      },
      series: [
        {
          name: "Actual",
          data: data.values.map(v => v === null ? null : Number(v)),
          color: "#0ea5e9",
        },
        {
          name: "Goal",
          data: data.goals.map(v => v === null ? null : Number(v)),
          color: "#22c55e",
        }
      ],
      xaxis: {
        categories: formattedDates,
        labels: {
          show: true,
          formatter: function (value) {
            return value;
          },
          style: {
            colors: '#64748b',
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
          },
          offsetY: 5,
        },
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            colors: '#64748b',
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
          },
          formatter: (value) => {
            return this.formatMetricValue(value, data.metric);
          },
          offsetX: -10,
        },
        title: {
          text: data.metric?.name || 'Value',
          style: {
            color: '#64748b',
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
          },
          offsetX: -10,
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 10,
        labels: {
          colors: '#64748b',
        },
        markers: {
          width: 8,
          height: 8,
          radius: 10,
        },
      },
    }

    if (typeof ApexCharts !== 'undefined') {
      this.chart = new ApexCharts(this.element, options);
      this.chart.render();
    }
  }

  getLastSixMondays() {
    const today = new Date();
    let lastMonday = new Date(today);
    lastMonday.setDate(today.getDate() - ((today.getDay() + 6) % 7) - 7);
    const mondays = [];

    for (let i = 5; i >= 0; i--) {
      const monday = new Date(lastMonday);
      monday.setDate(monday.getDate() - (i * 7));
      mondays.push(this.formatDate(monday));
    }

    return mondays;
  }

  formatDate(date) {
    const options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  isTurboPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }
}