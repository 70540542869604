import { application } from "./application"
import CbaCollapseController from "./cba_collapse_controller"
import DataRetrievalFormController from "./madmin/data_retrieval_form_controller"

application.register("hello", HelloController)
application.register("trailing-six-weeks-chart", TrailingSixWeeksChartController)
application.register("trailing-twelve-months-chart", TrailingTwelveMonthsChartController)
application.register("scorecard-metric-category-form", ScorecardMetricCategoryFormController)
application.register("scorecard-infinite-scroll-table", ScorecardInfiniteScrollTableController)
application.register("metric-form", MetricFormController)
application.register("switch-account", SwitchAccountController)
application.register("flash", FlashController)
application.register("impersonates", ImpersonatesController)
application.register("settings-tabs", SettingsTabsController)
application.register("metric-category-list", MetricCategoryListController)
application.register("inline-edit", InlineEditController)
application.register("metric-category-metrics", MetricCategoryMetricsController)
application.register("north-star-metric-selector", NorthStarMetricSelectorController)
application.register("precision-radial-charts", PrecisionRadialChartsController)
application.register("scorecard-flyout", ScorecardFlyoutController)
application.register("profile-menu", ProfileMenuController)
application.register("cba-collapse", CbaCollapseController)

import ClipboardController from "./clipboard_controller"
application.register("clipboard", ClipboardController)

import CollapseControlsController from "./collapse_controls_controller"
application.register("collapse-controls", CollapseControlsController)

import CustomModalController from "./custom_modal_controller"
application.register("custom-modal", CustomModalController)
application.register("data-retrieval-form", DataRetrievalFormController)

import Dashboard_metric_combobox_controller from "./dashboard_metric_combobox_controller";
application.register("dashboard-metric-combobox", Dashboard_metric_combobox_controller)

import EmbedController from "./embed_controller"
application.register("embed", EmbedController)

import FlashController from "./flash_controller"
application.register("flash", FlashController)

import FormulaTooltipController from "./formula_tooltip_controller"
application.register("formula-tooltip", FormulaTooltipController)

import HelloController from "./hello_controller"
application.register("hello", HelloController)

import ImpersonatesController from "./impersonates_controller"
application.register("impersonates", ImpersonatesController)

import InlineEditController from "./inline_edit_controller"
application.register("inline-edit", InlineEditController)

import Madmin__AccountsSearchController from "./madmin/accounts_search_controller"
application.register("madmin--accounts-search", Madmin__AccountsSearchController)

import Madmin__BusinessAreaTabsController from "./madmin/business_area_tabs_controller"
application.register("madmin--business-area-tabs", Madmin__BusinessAreaTabsController)

import Madmin__CategoryAccordionController from "./madmin/category_accordion_controller"
application.register("madmin--category-accordion", Madmin__CategoryAccordionController)

import Madmin__CategoryFieldsController from "./madmin/category_fields_controller"
application.register("madmin--category-fields", Madmin__CategoryFieldsController)

import Madmin__CategoryNameController from "./madmin/category_name_controller"
application.register("madmin--category-name", Madmin__CategoryNameController)

import Madmin__MetricDefinitionComboboxController from "./madmin/metric_definition_combobox_controller"
application.register("madmin--metric-definition-combobox", Madmin__MetricDefinitionComboboxController)

import Madmin__MetricDefinitionFormController from "./madmin/metric_definition_form_controller"
application.register("madmin--metric-definition-form", Madmin__MetricDefinitionFormController)

import Madmin__MetricFieldsController from "./madmin/metric_fields_controller"
application.register("madmin--metric-fields", Madmin__MetricFieldsController)

import Madmin__MetricSortableController from "./madmin/metric_sortable_controller"
application.register("madmin--metric-sortable", Madmin__MetricSortableController)

import Madmin__MetricsNestedFormController from "./madmin/metrics_nested_form_controller"
application.register("madmin--metrics-nested-form", Madmin__MetricsNestedFormController)

import MarkWeekClosedController from "./mark_week_closed_controller"
application.register("mark-week-closed", MarkWeekClosedController)

import MetricCategoryListController from "./metric_category_list_controller"
application.register("metric-category-list", MetricCategoryListController)

import MetricCategoryMetricsController from "./metric_category_metrics_controller"
application.register("metric-category-metrics", MetricCategoryMetricsController)

import MetricComboboxController from "./metric_combobox_controller"
application.register("metric-combobox", MetricComboboxController)

import MetricFormController from "./metric_form_controller"
application.register("metric-form", MetricFormController)

import MetricSelectionController from "./metric_selection_controller"
application.register("metric-selection", MetricSelectionController)

import NorthStarMetricSelectorController from "./north_star_metric_selector_controller"
application.register("north-star-metric-selector", NorthStarMetricSelectorController)

import OrgChartAutoSubmitController from "./org_chart_auto_submit_controller"
application.register("org-chart-auto-submit", OrgChartAutoSubmitController)

import OrgChartController from "./org_chart_controller"
application.register("org-chart", OrgChartController)

import OrgChartDrawerController from "./org_chart_drawer_controller"
application.register("org-chart-drawer", OrgChartDrawerController)

import OrgChartFormController from "./org_chart_form_controller"
application.register("org-chart-form", OrgChartFormController)

import OrgChartSearchFormController from "./org_chart_search_form_controller"
application.register("org-chart-search-form", OrgChartSearchFormController)

import PrecisionRadialChartsController from "./precision_radial_charts_controller"
application.register("precision-radial-charts", PrecisionRadialChartsController)

import ProfileMenuController from "./profile_menu_controller"
application.register("profile-menu", ProfileMenuController)

import ScorecardFlyoutController from "./scorecard_flyout_controller"
application.register("scorecard-flyout", ScorecardFlyoutController)

import ScorecardInfiniteScrollTableController from "./scorecard_infinite_scroll_table_controller"
application.register("scorecard-infinite-scroll-table", ScorecardInfiniteScrollTableController)

import ScorecardMetricCategoryFormController from "./scorecard_metric_category_form_controller"
application.register("scorecard-metric-category-form", ScorecardMetricCategoryFormController)

import SettingsTabsController from "./settings_tabs_controller"
application.register("settings-tabs", SettingsTabsController)

import SwitchAccountController from "./switch_account_controller"
application.register("switch-account", SwitchAccountController)

import TimezoneComboboxController from "./timezone_combobox_controller"
application.register("timezone-combobox", TimezoneComboboxController)

import TrailingSixWeeksChartController from "./trailing_six_weeks_chart_controller"
application.register("trailing-six-weeks-chart", TrailingSixWeeksChartController)

import TrailingTwelveMonthsChartController from "./trailing_twelve_months_chart_controller"
application.register("trailing-twelve-months-chart", TrailingTwelveMonthsChartController)

import WeeklyBusinessReviewChartsController from "./weekly_business_review_charts_controller"
application.register("weekly-business-review-charts", WeeklyBusinessReviewChartsController)

import WeeklyBusinessReviewSectionNavigationController from "./weekly_business_review_section_navigation_controller"
application.register("weekly-business-review-section-navigation", WeeklyBusinessReviewSectionNavigationController)

import WeeklyBusinessReviewSectionVisibilityController from "./weekly_business_review_section_visibility_controller"
application.register("weekly-business-review-section-visibility", WeeklyBusinessReviewSectionVisibilityController)
