import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["weeklyBusinessReviewSection", "weeklyBusinessReviewFooter", "weeklyBusinessReviewPrevButton", "weeklyBusinessReviewNextButton", "weeklyBusinessReviewCurrentSection", "weeklyBusinessReviewNextSection", "weeklyBusinessReviewPrevContainer", "weeklyBusinessReviewNavLink"]

  connect() {
    this.setupIntersectionObserver()
    this.setupNavLinkHandlers()
  }

  setupNavLinkHandlers() {
    if (this.hasWeeklyBusinessReviewNavLinkTarget) {
      this.weeklyBusinessReviewNavLinkTargets.forEach(link => {
        link.addEventListener('click', (event) => {
          event.preventDefault()
          const sectionId = link.getAttribute('href').substring(1) // Remove the # from the href
          const section = document.getElementById(sectionId)
          if (section) {
            section.scrollIntoView({ behavior: "smooth" })
          }
        })
      })
    }

    // Handle initial hash in URL
    if (window.location.hash) {
      const sectionId = window.location.hash.substring(1)
      const section = document.getElementById(sectionId)
      if (section) {
        setTimeout(() => {
          section.scrollIntoView({ behavior: "smooth" })
        }, 100)
      }
    }
  }

  setupIntersectionObserver() {
    const options = {
      root: null, // viewport
      rootMargin: "-10% 0px", // Reduced margin to improve accuracy
      threshold: [0.3, 0.5] // Multiple thresholds for better detection
    }

    this.observer = new IntersectionObserver(this.handleIntersection.bind(this), options)
    this.weeklyBusinessReviewSectionTargets.forEach(section => {
      this.observer.observe(section)
    })
  }

  handleIntersection(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const currentSection = entry.target
        const currentIndex = this.weeklyBusinessReviewSectionTargets.indexOf(currentSection)
        const prevSection = currentIndex > 0 ? this.weeklyBusinessReviewSectionTargets[currentIndex - 1] : null
        const nextSection = currentIndex < this.weeklyBusinessReviewSectionTargets.length - 1 ? this.weeklyBusinessReviewSectionTargets[currentIndex + 1] : null

        // Update navigation links
        if (this.hasWeeklyBusinessReviewNavLinkTarget) {
          this.weeklyBusinessReviewNavLinkTargets.forEach(link => {
            const href = link.getAttribute('href')
            if (href === `#${currentSection.id}`) {
              link.classList.remove('text-gray-300', 'hover:bg-gray-800', 'hover:text-white')
              link.classList.add('bg-gray-800', 'text-white')
            } else {
              link.classList.remove('bg-gray-800', 'text-white')
              link.classList.add('text-gray-300', 'hover:bg-gray-800', 'hover:text-white')
            }
          })
        }

        // Update footer content
        if (this.hasWeeklyBusinessReviewCurrentSectionTarget) {
          this.weeklyBusinessReviewCurrentSectionTarget.textContent = prevSection ? (prevSection.dataset.sectionName || "") : ""
        }

        if (this.hasWeeklyBusinessReviewNextSectionTarget) {
          this.weeklyBusinessReviewNextSectionTarget.textContent = nextSection ? (nextSection.dataset.sectionName || "") : ""
        }

        // Update button states and navigation
        if (this.hasWeeklyBusinessReviewPrevContainerTarget) {
          this.weeklyBusinessReviewPrevContainerTarget.classList.toggle("hidden", !prevSection)
          if (prevSection) {
            this.weeklyBusinessReviewPrevButtonTarget.dataset.sectionId = prevSection.id
          }
        }

        if (this.hasWeeklyBusinessReviewNextButtonTarget) {
          this.weeklyBusinessReviewNextButtonTarget.classList.toggle("invisible", !nextSection)
          if (nextSection) {
            this.weeklyBusinessReviewNextButtonTarget.dataset.sectionId = nextSection.id
          }
        }
      }
    })
  }

  navigate(event) {
    const sectionId = event.currentTarget.dataset.sectionId
    if (sectionId) {
      const section = document.getElementById(sectionId)
      if (section) {
        section.scrollIntoView({ behavior: "smooth" })
      }
    }
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
} 