import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "section", "select"]

  connect() {
    this.showSection(this.selectedBusinessAreaId)
  }

  get selectedBusinessAreaId() {
    const activeTab = this.tabTargets.find(tab => tab.getAttribute("aria-current") === "page")
    return activeTab ? activeTab.dataset.businessAreaId : this.tabTargets[0].dataset.businessAreaId
  }

  switchTab(event) {
    const selectedTab = event.currentTarget
    const businessAreaId = selectedTab.dataset.businessAreaId

    // Update tab states
    this.tabTargets.forEach(tab => {
      tab.setAttribute("aria-current", "false")
      tab.classList.remove("bg-primary-100", "text-primary-700")
      tab.classList.add("text-gray-500", "hover:text-gray-700")
    })

    selectedTab.setAttribute("aria-current", "page")
    selectedTab.classList.remove("text-gray-500", "hover:text-gray-700")
    selectedTab.classList.add("bg-primary-100", "text-primary-700")

    this.showSection(businessAreaId)
  }

  showSection(businessAreaId) {
    this.sectionTargets.forEach(section => {
      if (section.dataset.businessAreaId === businessAreaId) {
        section.classList.remove("hidden")
      } else {
        section.classList.add("hidden")
      }
    })
  }

  selectChange(event) {
    const selectedOption = event.target.selectedOptions[0]
    const businessAreaId = selectedOption.dataset.businessAreaId
    this.showSection(businessAreaId)
  }
} 