import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "container"]

  connect() {
    console.log("MetricFieldsController connected")
    this.setupExistingFields()
  }

  setupExistingFields() {
    // Setup event listeners for all existing metric field rows
    this.element.querySelectorAll('tr.nested-fields').forEach(row => {
      const dataTypeSelect = row.querySelector('select[name*="[data_type]"]')
      const numberTypeSelect = row.querySelector('select[name*="[number_type]"]')
      const aggregationSelect = row.querySelector('select[name*="[aggregation_type]"]')
      if (dataTypeSelect) {
        this.setupDataTypeListener(dataTypeSelect)
        this.setupNumberTypeListener(numberTypeSelect)
        this.setupAggregationTypeListener(aggregationSelect)
        // Trigger initial update
        this.updateSelectOptions(dataTypeSelect)
      }
    })
  }

  add(event) {
    event.preventDefault()
    
    // Get template content and replace placeholder ID
    const content = this.templateTarget.innerHTML
    const newContent = content.replace(/NEW_RECORD/g, new Date().getTime().toString())
    
    // Insert the new row
    this.containerTarget.insertAdjacentHTML('beforeend', newContent)

    // Dispatch event for position handling
    const addEvent = new CustomEvent('metric-fields:add', {
      bubbles: true,
      detail: { timestamp: new Date().getTime() }
    })
    this.element.dispatchEvent(addEvent)
  }

  remove(event) {
    event.preventDefault()
    const row = event.target.closest('.nested-fields')
    
    // If it's a persisted record, mark for deletion
    const destroyField = row.querySelector('input[name$="[_destroy]"]')
    if (destroyField) {
      destroyField.value = '1'
      row.style.display = 'none'
    } else {
      // If it's a new record, just remove the element
      row.remove()
    }

    // Update positions after removal
    const updateEvent = new CustomEvent('metric-fields:add', {
      bubbles: true,
      detail: { timestamp: new Date().getTime() }
    })
    this.element.dispatchEvent(updateEvent)
  }

  setupDataTypeListener(dataTypeSelect) {
    dataTypeSelect.addEventListener('change', (event) => {
      this.updateSelectOptions(event.target)
    })
  }

  setupNumberTypeListener(numberTypeSelect) {
    numberTypeSelect.addEventListener('change', (event) => {
      const row = event.target.closest('tr')
      const dataTypeSelect = row.querySelector('select[name*="[data_type]"]')
      this.updateSelectOptions(dataTypeSelect)
    })
  }

  setupAggregationTypeListener(aggregationSelect) {
    aggregationSelect.addEventListener('change', (event) => {
      const row = event.target.closest('tr')
      const pacingSelect = row.querySelector('select[name*="[pacing_type]"]')
      if (!pacingSelect.dataset.originalOptions) {
        pacingSelect.dataset.originalOptions = pacingSelect.innerHTML
      }
      pacingSelect.innerHTML = pacingSelect.dataset.originalOptions
      this.updatePacingOptions(event.target, pacingSelect)
    })
  }

  updateSelectOptions(dataTypeSelect) {
    const row = dataTypeSelect.closest('tr')
    const aggregationSelect = row.querySelector('select[name*="[aggregation_type]"]')
    const pacingSelect = row.querySelector('select[name*="[pacing_type]"]')
    const numberTypeSelect = row.querySelector('select[name*="[number_type]"]')

    const dataType = dataTypeSelect.value
    const currentNumberType = numberTypeSelect.value

    // Store original options if not already stored
    if (!aggregationSelect.dataset.originalOptions) {
      aggregationSelect.dataset.originalOptions = aggregationSelect.innerHTML
      pacingSelect.dataset.originalOptions = pacingSelect.innerHTML
    }

    // Reset to original options
    aggregationSelect.innerHTML = aggregationSelect.dataset.originalOptions
    pacingSelect.innerHTML = pacingSelect.dataset.originalOptions

    // Apply restrictions based on data type
    switch (dataType) {
      case 'direct_entry':
        // Remove calculation and prior_period_growth from aggregation types
        this.removeOption(aggregationSelect, 'calculation')
        this.removeOption(aggregationSelect, 'prior_period_growth')
        if (currentNumberType === 'percentage') {
          // Force latest for percentage metrics
          this.keepOnlyOptions(aggregationSelect, ['latest'])
        }
        break

      case 'calculated':
        // Only allow calculation aggregation type
        this.keepOnlyOptions(aggregationSelect, ['calculation'])
        // Remove linear_ppgp from pacing
        this.removeOption(pacingSelect, 'linear_ppgp')
        break

      case 'prior_period_growth':
        if (currentNumberType === 'percentage') {
          // Force latest for percentage metrics
          this.keepOnlyOptions(aggregationSelect, ['latest'])
        } else {
          this.keepOnlyOptions(aggregationSelect, ['sum'])
        }
        break
    }

    // Set default value if current value is not in available options
    if (!this.isOptionAvailable(aggregationSelect, aggregationSelect.value)) {
      aggregationSelect.value = aggregationSelect.options[0].value
    }

    // Update pacing options based on current aggregation type
    this.updatePacingOptions(aggregationSelect, pacingSelect)
  }

  updatePacingOptions(aggregationSelect, pacingSelect) {
    const aggregationType = aggregationSelect.value

    switch (aggregationType) {
      case 'sum':
        this.keepOnlyOptions(pacingSelect, ['linear_total', 'direct_comparison'])
        break
      case 'latest':
        this.keepOnlyOptions(pacingSelect, ['linear_growth', 'direct_comparison'])
        break
      case 'calculation':
        this.keepOnlyOptions(pacingSelect, ['linear_growth', 'direct_comparison', 'linear_total'])
        break
    }

    // Set default value if current value is not in available options
    if (!this.isOptionAvailable(pacingSelect, pacingSelect.value)) {
      pacingSelect.value = pacingSelect.options[0].value
    }
  }

  removeOption(select, valueToRemove) {
    const option = select.querySelector(`option[value="${valueToRemove}"]`)
    if (option) option.remove()
  }

  keepOnlyOptions(select, valuesToKeep) {
    Array.from(select.options).forEach(option => {
      if (!valuesToKeep.includes(option.value)) {
        option.remove()
      }
    })
  }

  isOptionAvailable(select, value) {
    return Array.from(select.options).some(option => option.value === value)
  }
} 