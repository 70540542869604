import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "select", "options", "button"]
  static values = {
    metrics: Array,
    currentCollectionId: String
  }

  connect() {
    this.filterMetrics = this.filterMetrics.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    document.addEventListener("click", this.handleClickOutside)
    this.originalValue = this.inputTarget.value

    // Listen for collection filter events
    this.element.addEventListener('filterByCollection', this.handleFilterByCollection.bind(this))

    // Add input event listener directly
    this.inputTarget.addEventListener('input', () => {
      this.filterMetrics()
    })

    // Get initial collection ID from category select
    const categorySelect = document.querySelector('[data-metric-definition-form-target="categorySelect"]')
    if (categorySelect) {
      const selectedCategoryId = categorySelect.value
      const collectionIds = JSON.parse(categorySelect.dataset.collectionIds || '{}')
      const selectedCollectionId = collectionIds[selectedCategoryId]

      if (selectedCollectionId) {
        this.currentCollectionIdValue = selectedCollectionId
        this.filterMetrics(true) // Pass true for silent filtering
      }
    }
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside)
    this.element.removeEventListener('filterByCollection', this.handleFilterByCollection.bind(this))
  }

  handleFilterByCollection(event) {
    const { collectionId } = event.detail
    this.currentCollectionIdValue = collectionId
    this.filterMetrics()
  }

  filterMetrics(silent = false) {
    const query = this.inputTarget.value.toLowerCase()
    let filteredMetrics = this.metricsValue

    // Filter by collection if one is selected
    if (this.currentCollectionIdValue) {
      filteredMetrics = filteredMetrics.filter(([_, __, ___, collection]) => {
        return collection && collection.toString() === this.currentCollectionIdValue.toString()
      })
    }

    // Then filter by search query
    filteredMetrics = filteredMetrics.filter(([name, shortId, category, cba]) => {
      return name.toLowerCase().includes(query) ||
        (shortId && shortId.toLowerCase().includes(query)) ||
        (category && category.toLowerCase().includes(query)) ||
        (cba && cba.toLowerCase().includes(query))
    })

    this.renderOptions(filteredMetrics)

    // Only show the options if not silent
    if (silent) {
      this.optionsTarget.classList.add('hidden')
    } else {
      this.optionsTarget.classList.remove('hidden')
    }
  }

  renderOptions(metrics) {
    if (metrics.length === 0) {
      this.optionsTarget.innerHTML = `
        <li class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900">
          No metrics found
        </li>
      `
      return
    }

    // Group metrics by CBA and MetricCategory
    const grouped = metrics.reduce((acc, metric) => {
      const [name, id, category, cba] = metric
      if (!acc[cba]) {
        acc[cba] = {}
      }
      if (!acc[cba][category]) {
        acc[cba][category] = []
      }
      acc[cba][category].push([name, id])
      return acc
    }, {})

    this.optionsTarget.innerHTML = Object.entries(grouped).map(([cba, categories], cbaIndex) => `
      ${cbaIndex > 0 ? '<li class="border-t border-gray-200"></li>' : ''}
      <li class="relative mt-0 py-2.5 pl-3 pr-9 text-xs font-semibold text-gray-500 uppercase tracking-wider bg-gray-100 border-gray-200 border-b">
        ${cba}
      </li>
      ${Object.entries(categories).map(([category, metrics]) => `
        <li class="relative py-2 pl-3 pr-9 text-xs font-medium text-gray-700">
          ${category}
        </li>
        ${metrics.map(([name, id]) => `
          <li class="relative cursor-default select-none py-2 pl-6 pr-9 hover:bg-primary-600 hover:text-white group"
              role="option"
              data-action="click->metric-definition-combobox#selectMetric"
              data-name="${name}"
              data-value="${id}">
            <span class="block truncate">
              ${name}
            </span>
          </li>
        `).join('')}
      `).join('')}
    `).join('')
  }

  toggleOptions() {
    const isExpanded = this.inputTarget.getAttribute('aria-expanded') === 'true'

    if (isExpanded) {
      this.closeOptions()
    } else {
      this.openOptions()
    }
  }

  openOptions() {
    this.inputTarget.setAttribute('aria-expanded', 'true')
    this.optionsTarget.classList.remove('hidden')

    // Position the dropdown
    this.positionDropdown()

    // Clear the input value when opening
    this.inputTarget.value = ''

    // Show all options
    this.filterMetrics()
    this.inputTarget.focus()
  }

  positionDropdown() {
    // Reset any previous positioning
    this.optionsTarget.classList.remove('bottom-full', 'mb-1', 'mt-1')

    const inputRect = this.inputTarget.getBoundingClientRect()
    const dropdownHeight = 240 // Max height of dropdown (15rem = 240px)
    const viewportHeight = window.innerHeight
    const spaceBelow = viewportHeight - inputRect.bottom
    const spaceAbove = inputRect.top

    // If there's not enough space below and more space above, position above
    if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
      this.optionsTarget.classList.add('bottom-full', 'mb-1')
    } else {
      this.optionsTarget.classList.add('mt-1')
    }
  }

  closeOptions() {
    this.inputTarget.setAttribute('aria-expanded', 'false')
    this.optionsTarget.classList.add('hidden')
    if (!this.inputTarget.value.trim()) {
      this.inputTarget.value = this.originalValue
    }
  }

  selectMetric(event) {
    const { name, value } = event.currentTarget.dataset
    this.inputTarget.value = name
    this.originalValue = name
    this.selectTarget.value = value

    // Dispatch change event on the select element
    const changeEvent = new Event('change', { bubbles: true })
    this.selectTarget.dispatchEvent(changeEvent)

    // Ensure the hidden select's value is updated and the change event is triggered
    if (this.selectTarget.name.includes('source_metric_definition_id')) {
      // This is a source metric selection
      const formSelect = this.element.querySelector('select[name*="source_metric_definition_id"]')
      if (formSelect) {
        formSelect.value = value
        formSelect.dispatchEvent(new Event('change', { bubbles: true }))
      }
    }

    this.closeOptions()
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.closeOptions()
    }
  }
}