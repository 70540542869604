  import { Controller } from "@hotwired/stimulus"

  export default class extends Controller {
    static targets = ["svg", "content"]
    static values = {
      nodeWidth: { type: Number, default: 130 },
      nodeHeight: { type: Number, default: 120 },
      levelHeight: { type: Number, default: 180 },
      horizontalSpacing: { type: Number, default: 50 }
    }

    connect() {
      // Ensure content target is properly initialized as SVG
      const contentElement = this.contentTarget
      if (!(contentElement instanceof SVGElement)) {
        const svg = document.createElementNS("http://www.w3.org/2000/svg", "g")
        Array.from(contentElement.attributes).forEach(attr => {
          svg.setAttribute(attr.name, attr.value)
        })
        svg.innerHTML = contentElement.innerHTML
        contentElement.parentNode.replaceChild(svg, contentElement)
      }

      this.scale = 0.7
      this.minScale = 0.5
      this.maxScale = 5
      this.zoomSensitivity = 0.0005  // Adjustable zooming sensitivity factor
      this.minScaleChange = 0.01     // Minimum scale change threshold

      // Track last zoom time for debouncing
      this.lastZoomTime = 0
      this.zoomDebounceDelay = 50  // ms between zoom events

      this.panX = 0
      this.panY = 0
      this.isPanning = false
      this.isZooming = false
      this.expandedNodes = this.expandedNodes || new Set()
      this.lastTouchDistance = null
      this.lastTouchCenter = null

      this.boundZoom = this.zoom.bind(this)
      this.boundHandleTouchStart = this.handleTouchStart.bind(this)
      this.boundHandleTouchMove = this.handleTouchMove.bind(this)
      this.boundHandleTouchEnd = this.handleTouchEnd.bind(this)
      this.svgTarget.addEventListener('wheel', this.boundZoom, { passive: false, capture: true })
      this.svgTarget.addEventListener('touchstart', this.boundHandleTouchStart, { passive: false })
      this.svgTarget.addEventListener('touchmove', this.boundHandleTouchMove, { passive: false })
      this.svgTarget.addEventListener('touchend', this.boundHandleTouchEnd, { passive: false })


      const svgRect = this.svgTarget.getBoundingClientRect()

      requestAnimationFrame(() => {
        // this.applyTransform()
        this.drawConnections()
        this.updateLayout()
        this.expandAllNodes()
        // Center the content initially
        this.centerContent()
      })
    }

    disconnect() {
      this.svgTarget.removeEventListener('wheel', this.boundZoom, { capture: true })
      this.svgTarget.removeEventListener('touchstart', this.boundHandleTouchStart)
      this.svgTarget.removeEventListener('touchmove', this.boundHandleTouchMove)
      this.svgTarget.removeEventListener('touchend', this.boundHandleTouchEnd)
    }

    centerContent() {
      const svgRect = this.svgTarget.getBoundingClientRect()
      const contentBBox = this.contentTarget.getBBox()

      // Calculate the center position considering the content's size and scale
      this.panX = (svgRect.width - (contentBBox.width * this.scale)) / 2 - (contentBBox.x * this.scale)
      this.panY = (svgRect.height - (contentBBox.height * this.scale)) / 2 - (contentBBox.y * this.scale)

      // Adjust Y position to be slightly higher
      this.panY = Math.max(70, this.panY * 0.8)  // Ensure minimum padding from top

      this.applyTransform()
    }

    expandAllNodes() {
      // Start with the head node
      const headNode = this.element.querySelector('.node[data-is-head="true"]')
      if (!headNode) return

      // Helper function to expand a node and all its children recursively
      const expandNodeAndChildren = (node) => {
        const nodeId = node.dataset.id
        this.expandedNodes.add(nodeId)

        // Update button text
        const buttonGroup = this.element.querySelector(`.expand-button-group[data-node-id="${nodeId}"]`)
        if (buttonGroup) {
          const buttonText = buttonGroup.querySelector('.expand-button-text')
          buttonText.textContent = '-'
        }

        this.expandChildren(nodeId)

        // Recursively expand all children
        const children = this.element.querySelectorAll(`.node[data-parent-id="${nodeId}"]`)
        children.forEach(child => expandNodeAndChildren(child))
      }

      // Start expansion from head node
      expandNodeAndChildren(headNode)
      this.updateLayout()
    }

    autoExpandFirstNodes() {
      // Start with the head node
      const headNode = this.element.querySelector('.node[data-is-head="true"]')
      if (!headNode) return

      let currentNode = headNode
      while (currentNode) {
        const nodeId = currentNode.dataset.id

        // Expand this node
        this.expandedNodes.add(nodeId)
        const buttonGroup = this.element.querySelector(`.expand-button-group[data-node-id="${nodeId}"]`)
        if (buttonGroup) {
          const buttonText = buttonGroup.querySelector('.expand-button-text')
          buttonText.textContent = '-'
        }
        this.expandChildren(nodeId)

        // Find the first child of this node (if any)
        const children = this.element.querySelectorAll(`.node[data-parent-id="${nodeId}"]`)
        currentNode = children.length > 0 ? children[0] : null
      }

      this.updateLayout()
    }

    toggleChildren(event) {
      const nodeId = event.currentTarget.dataset.nodeId
      const buttonGroup = this.element.querySelector(`.expand-button-group[data-node-id="${nodeId}"]`)
      const buttonText = buttonGroup.querySelector('.expand-button-text')

      // Simply toggle the current node without affecting siblings
      if (this.expandedNodes.has(nodeId)) {
        // Collapse this node
        this.expandedNodes.delete(nodeId)
        buttonText.textContent = '+'
        this.collapseChildren(nodeId)
      } else {
        // Expand this node
        this.expandedNodes.add(nodeId)
        buttonText.textContent = '-'
        this.expandChildren(nodeId)
      }

      this.updateLayout()
    }

    collapseChildren(nodeId) {
      const childNodes = this.element.querySelectorAll(`[data-parent-id="${nodeId}"]`)
      childNodes.forEach(node => {
        // Hide the node
        node.closest('.node').classList.add('hidden')

        // Hide the connection
        const connection = this.element.querySelector(`.connection-${nodeId}-${node.dataset.id}`)
        if (connection) {
          connection.style.display = 'none'
        }

        // Reset the expand button if this child has its own children
        const childButtonGroup = this.element.querySelector(`.expand-button-group[data-node-id="${node.dataset.id}"]`)
        if (childButtonGroup) {
          const childButtonText = childButtonGroup.querySelector('.expand-button-text')
          childButtonText.textContent = '+'
        }

        // Recursively collapse children
        if (this.expandedNodes.has(node.dataset.id)) {
          this.expandedNodes.delete(node.dataset.id)
          this.collapseChildren(node.dataset.id)
        }
      })
    }

    expandChildren(nodeId) {
      const childNodes = this.element.querySelectorAll(`[data-parent-id="${nodeId}"]`)
      childNodes.forEach(node => {
        // Show the node
        node.closest('.node').classList.remove('hidden')

        // Show the connection
        const connection = this.element.querySelector(`.connection-${nodeId}-${node.dataset.id}`)
        if (connection) {
          connection.style.display = ''
        }
      })
    }

    getSubtreeWidth(node) {
      if (!this.expandedNodes.has(node.dataset.id)) {
        return this.nodeWidthValue
      }

      const children = Array.from(this.element.querySelectorAll(
        `.node[data-parent-id="${node.dataset.id}"]:not(.hidden)`
      ))

      if (children.length === 0) {
        return this.nodeWidthValue
      }

      // Calculate space needed for all children including spacing
      const totalChildrenWidth = children.reduce((total, child, index) => {
        const childWidth = this.getSubtreeWidth(child)
        // Add spacing between nodes, but not after the last one
        return total + childWidth + (index < children.length - 1 ? this.horizontalSpacingValue : 0)
      }, 0)

      // Return the larger of the node's width or its children's total width
      return Math.max(this.nodeWidthValue, totalChildrenWidth)
    }

    positionNodes(node, level, xOffset = 0) {
      if (!node) return;
      const nodeId = node.dataset.id
      const isHead = node.dataset.isHead === "true"

      // Position current node
      if (isHead) {
        node._position = { x: 0, y: 0 }
      } else {
        node._position = { x: xOffset, y: level * this.levelHeightValue }
      }

      if (this.expandedNodes.has(nodeId)) {
        const children = Array.from(this.element.querySelectorAll(
          `.node[data-parent-id="${nodeId}"]:not(.hidden)`
        ))

        if (children.length > 0) {
          // Get the width of each child's subtree
          const childrenWidths = children.map(child => this.getSubtreeWidth(child))

          // Calculate total width including spacing between children
          const totalWidth = childrenWidths.reduce((sum, width, index) =>
            sum + width + (index < children.length - 1 ? this.horizontalSpacingValue : 0), 0)

          // Calculate the starting position for the first child
          let startX
          startX = xOffset + (this.nodeWidthValue - totalWidth) / 2

          // Position each child
          children.forEach((child, index) => {
            const childWidth = childrenWidths[index]
            // Center the child within its allocated space
            const childX = startX + (childWidth - this.nodeWidthValue) / 2
            this.positionNodes(child, level + 1, childX)
            startX += childWidth + this.horizontalSpacingValue
          })
        }
      }
    }

    updateLayout() {
      const headNode = this.element.querySelector('.node[data-is-head="true"]')
      if (!headNode) return

      // Calculate positions starting from head
      this.positionNodes(headNode, 0)

      // Apply calculated positions
      const allNodes = this.element.querySelectorAll('.node')
      allNodes.forEach(node => {
        if (node._position) {
          node.setAttribute('transform',
            `translate(${node._position.x}, ${node._position.y})`
          )

          // Update arrow visibility based on sibling position
          this.updateArrowVisibility(node)

          // Fix foreignObject case sensitivity
          const foreignObj = node.querySelector('foreignobject')
          if (foreignObj) {
            // Create a new element with correct case
            const correctElement = document.createElementNS("http://www.w3.org/2000/svg", "foreignObject")

            // Copy all attributes
            Array.from(foreignObj.attributes).forEach(attr => {
              correctElement.setAttribute(attr.name, attr.value)
            })

            // Copy innerHTML
            correctElement.innerHTML = foreignObj.innerHTML

            // Replace the old element
            foreignObj.parentNode.replaceChild(correctElement, foreignObj)
          }
        }
      })

      this.drawConnections()
    }

    updateArrowVisibility(node) {
      if (node.dataset.isHead === "true") return

      const parentId = node.dataset.parentId
      const siblings = Array.from(
        this.element.querySelectorAll(`.node[data-parent-id="${parentId}"]:not(.hidden)`)
      ).sort((a, b) => parseInt(a.dataset.position) - parseInt(b.dataset.position))

      // Find current node's index in sorted siblings array
      const currentIndex = siblings.findIndex(sibling => sibling.dataset.id === node.dataset.id)
      const isFirst = currentIndex === 0
      const isLast = currentIndex === siblings.length - 1

      // Update arrows visibility based on position in siblings
      const rightArrow = node.querySelector('.move-right-button')
      const leftArrow = node.querySelector('.move-left-button')

      if (rightArrow) {
        rightArrow.style.display = !isLast ? '' : 'none'
      }
      if (leftArrow) {
        leftArrow.style.display = !isFirst ? '' : 'none'
      }
    }

    async moveNode(event, direction) {
      event.preventDefault()
      const node = event.target.closest('.node')
      const nodeId = node.dataset.id
      const parentId = node.dataset.parentId

      // Find all siblings
      const siblings = Array.from(
        this.element.querySelectorAll(`.node[data-parent-id="${parentId}"]:not(.hidden)`)
      ).sort((a, b) => parseInt(a.dataset.position) - parseInt(b.dataset.position))

      // Find current node's index
      const currentIndex = siblings.findIndex(sibling => sibling.dataset.id === nodeId)

      // Check bounds based on direction
      if (direction === 'left' && currentIndex <= 0) return
      if (direction === 'right' && currentIndex >= siblings.length - 1) return

      // Get target node based on direction
      const targetNode = siblings[currentIndex + (direction === 'left' ? -1 : 1)]
      const targetPosition = parseInt(targetNode.dataset.position)

      try {
        const state = this.saveOrgChartState()
        await this.updateNodePosition(nodeId, targetPosition)
        await this.restoreOrgChartState(state)
      } catch (error) {
        console.error('Error updating position:', error)
      }
    }

    async updateNodePosition(nodeId, targetPosition) {
      const csrfToken = document.querySelector("meta[name='csrf-token']")?.getAttribute("content")
      const response = await fetch(`/organization_relationships/${nodeId}/update_position`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
          'Accept': 'text/vnd.turbo-stream.html'
        },
        body: JSON.stringify({
          organization_relationship: {
            position: targetPosition
          }
        })
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const html = await response.text()
      Turbo.renderStreamMessage(html)
    }

    saveOrgChartState() {
      const state = {
        transform: this.contentTarget.getAttribute('transform'),
        scale: this.scale,
        panX: this.panX,
        panY: this.panY,
        expandedNodes: Array.from(this.expandedNodes)
      }
      sessionStorage.setItem('orgChartState', JSON.stringify(state))
      return state
    }

    async restoreOrgChartState(state) {
      await new Promise(resolve => setTimeout(resolve, 100))
      const newContentElement = document.querySelector('[data-org-chart-target="content"]')
      if (!newContentElement) return

      const svg = document.createElementNS("http://www.w3.org/2000/svg", "g")
      Array.from(newContentElement.attributes).forEach(attr => {
        svg.setAttribute(attr.name, attr.value)
      })
      svg.innerHTML = newContentElement.innerHTML
      newContentElement.parentNode.replaceChild(svg, newContentElement)

      requestAnimationFrame(() => {
        this.scale = state.scale
        this.panX = state.panX
        this.panY = state.panY
        this.expandedNodes = new Set(state.expandedNodes)
        svg.setAttribute('transform', state.transform)

        this.expandNodesFromHead(this.element.querySelector('.node[data-is-head="true"]'), state)
        this.updateLayout()
        this.drawConnections()
      })

      sessionStorage.removeItem('orgChartState')
    }

    expandNodesFromHead(startNode, state) {
      if (!startNode) return

      const nodeId = startNode.dataset.id
      if (state.expandedNodes.includes(nodeId)) {
        this.expandChildren(nodeId)
        const buttonGroup = this.element.querySelector(
          `.expand-button-group[data-node-id="${nodeId}"]`
        )
        if (buttonGroup) {
          const buttonText = buttonGroup.querySelector('.expand-button-text')
          if (buttonText) buttonText.textContent = '-'
        }

        // Process children recursively
        const children = this.element.querySelectorAll(
          `.node[data-parent-id="${nodeId}"]`
        )
        children.forEach(child => this.expandNodesFromHead(child, state))
      }
    }

    // Update your existing move methods to use the new shared function
    async moveLeft(event) {
      await this.moveNode(event, 'left')
    }

    async moveRight(event) {
      await this.moveNode(event, 'right')
    }

    drawConnections() {
      const connections = this.element.querySelectorAll('.connection')
      connections.forEach(conn => {
        const sourceNode = this.element.querySelector(`.node[data-id="${conn.dataset.source}"]`)
        const targetNode = this.element.querySelector(`.node[data-id="${conn.dataset.target}"]`)

        if (sourceNode && targetNode && !targetNode.classList.contains('hidden')) {
          if (sourceNode._position && targetNode._position) {
            const sourcePos = sourceNode._position;
            const targetPos = targetNode._position;

            const startX = sourcePos.x + this.nodeWidthValue/2;
            const startY = sourcePos.y + this.nodeHeightValue;
            const endX = targetPos.x + this.nodeWidthValue/2;
            const endY = targetPos.y;

            // Draw orthogonal connection with smoother corners
            const midY = (startY + endY) / 2;
            const cornerRadius = 10;
            const path = `
              M ${startX} ${startY}
              L ${startX} ${midY - cornerRadius}
              Q ${startX} ${midY} ${startX + Math.sign(endX - startX) * cornerRadius} ${midY}
              L ${endX - Math.sign(endX - startX) * cornerRadius} ${midY}
              Q ${endX} ${midY} ${endX} ${midY + cornerRadius}
              L ${endX} ${endY}
            `;
            conn.setAttribute('d', path);
          }
        }
      })
    }


    getTransformCoordinates(element) {
      const transform = element.getAttribute('transform')
      const match = transform.match(/translate\(([^,]+),\s*([^)]+)\)/)
      return {
        x: parseFloat(match[1]),
        y: parseFloat(match[2])
      }
    }

    startPan(event) {
      if (event.button !== 0) return
      this.isPanning = true
      this.startX = event.clientX - this.panX
      this.startY = event.clientY - this.panY
      this.svgTarget.style.cursor = 'grabbing'
    }

    pan(event) {
      if (this.isPanning) {
        this.panX = event.clientX - this.startX
        this.panY = event.clientY - this.startY
        this.applyTransform()
      }
    }

    endPan() {
      this.isPanning = false
      this.svgTarget.style.cursor = 'grab'
    }

    getTouchCenter(touches) {
      return {
        x: (touches[0].clientX + touches[1].clientX) / 2,
        y: (touches[0].clientY + touches[1].clientY) / 2
      }
    }

    getTouchDistance(touches) {
      return Math.hypot(
        touches[1].clientX - touches[0].clientX,
        touches[1].clientY - touches[0].clientY
      )
    }

    handleTouchStart(event) {
      // Allow clicks on buttons, links, and foreignObject (nodes)
      if (event.target.closest('.expand-button, .expand-button-text, a, foreignObject, .move-left-button, .move-right-button')) {
        return;
      }

      // Only prevent default for pan/zoom gestures
      if (event.touches.length >= 1) {
        event.preventDefault();
      }

      if (event.touches.length === 1) {
        this.isPanning = true;
        this.isZooming = false;
        this.startX = event.touches[0].clientX - this.panX;
        this.startY = event.touches[0].clientY - this.panY;
      } else if (event.touches.length === 2) {
        this.isPanning = false;
        this.isZooming = true;
        this.lastTouchDistance = this.getTouchDistance(event.touches);
        this.lastTouchCenter = this.getTouchCenter(event.touches);
      }
    }

    handleTouchMove(event) {
      if (event.target.closest('.expand-button, .expand-button-text, a, foreignObject, .move-left-button, .move-right-button')) {
        return
      }

      if (this.isPanning || this.isZooming) {
        event.preventDefault()
      }

      if (event.touches.length === 1 && this.isPanning) {
        this.panX = event.touches[0].clientX - this.startX
        this.panY = event.touches[0].clientY - this.startY
        this.applyTransform()
      } else if (event.touches.length === 2 && this.isZooming) {
        const newTouchDistance = this.getTouchDistance(event.touches)
        const newTouchCenter = this.getTouchCenter(event.touches)

        // Calculate smooth zoom factor for touch
        const touchZoomRatio = newTouchDistance / this.lastTouchDistance
        const touchSensitivity = 0.3  // Adjust for touch-specific sensitivity
        const smoothTouchFactor = Math.exp((touchZoomRatio - 1) * touchSensitivity)

        const newScale = Math.min(Math.max(this.scale * smoothTouchFactor, this.minScale), this.maxScale)

        if (Math.abs(newScale - this.scale) >= this.minScaleChange) {
          const rect = this.svgTarget.getBoundingClientRect()
          const point = this.svgTarget.createSVGPoint()
          point.x = newTouchCenter.x - rect.left
          point.y = newTouchCenter.y - rect.top
          const svgPoint = point.matrixTransform(this.contentTarget.getCTM().inverse())

          const scaleDiff = newScale - this.scale
          this.panX -= svgPoint.x * scaleDiff
          this.panY -= svgPoint.y * scaleDiff
          this.scale = newScale

          this.contentTarget.style.transition = 'none'
          this.applyTransform()
        }

        this.lastTouchDistance = newTouchDistance
        this.lastTouchCenter = newTouchCenter
      }
    }

    handleTouchEnd(event) {
      // Check if the touch is on an expand/collapse button or link
      if (event.target.closest('.expand-button, .expand-button-text, a, foreignObject, .move-left-button, .move-right-button')) {
        return;
      }

      // Only prevent default if we were panning or zooming
      if (this.isPanning || this.isZooming) {
        event.preventDefault();
      }

      this.isPanning = false
      this.isZooming = false
      this.lastTouchDistance = null
      this.lastTouchCenter = null
    }

  zoom(event) {
    event.preventDefault()

    // Debounce zoom events
    const now = Date.now()
    if (now - this.lastZoomTime < this.zoomDebounceDelay) {
      return
    }
    this.lastZoomTime = now

    const mouseX = event.offsetX || event.touches?.[0]?.clientX || 0
    const mouseY = event.offsetY || event.touches?.[0]?.clientY || 0

    const point = this.svgTarget.createSVGPoint()
    point.x = mouseX
    point.y = mouseY
    const svgPoint = point.matrixTransform(this.contentTarget.getCTM().inverse())

    // Check if it's a trackpad gesture
    const isTrackpad = event.wheelDeltaY ? event.wheelDeltaY === -3 * event.deltaY : false
    // Additional check for Firefox which handles events differently
    const isFirefoxTrackpad = event.deltaMode === 0 && Math.abs(event.deltaY) < 40

    // Handle trackpad pinch zoom with adjusted sensitivity
    let deltaY = event.deltaY
    if (event.ctrlKey) {
      if (isTrackpad || isFirefoxTrackpad) {
        // Trackpad pinch-to-zoom (with Ctrl)
        deltaY = event.deltaY * 20
      } else {
        // Mouse wheel + Ctrl
        deltaY = event.deltaY
      }
    } else {
      deltaY = event.deltaY || (event.scale ? -100 * (event.scale - 1) : 0)
    }

    const adjustedSensitivity = isTrackpad || isFirefoxTrackpad ? this.zoomSensitivity * 2 : this.zoomSensitivity

    // Calculate smooth zoom factor with adjusted smoothing
    const smoothFactor = Math.exp(-Math.abs(deltaY) * adjustedSensitivity)
    const zoomFactor = deltaY > 0 ? smoothFactor : 1/smoothFactor

    // Calculate new scale
    const newScale = Math.min(Math.max(this.scale * zoomFactor, this.minScale), this.maxScale)

    // Increased minimum scale change for trackpad to prevent tiny adjustments
    const minChange = isTrackpad || isFirefoxTrackpad ? this.minScaleChange * 1.2 : this.minScaleChange
    if (Math.abs(newScale - this.scale) < minChange) {
      return
    }

    // Apply zoom
    const scaleDiff = newScale - this.scale
    this.scale = newScale
    this.panX = this.panX - (svgPoint.x * scaleDiff)
    this.panY = this.panY - (svgPoint.y * scaleDiff)

    this.applyTransform()

    // Shorter transition for trackpad for more responsive feel
    requestAnimationFrame(() => {
      this.contentTarget.style.transition = `transform ${isTrackpad || isFirefoxTrackpad ? '0.05s' : '0.1s'} ease-out`
    })
  }

  zoomIn() {
    this.updateZoom(1.1, true)
  }

  zoomOut() {
    this.updateZoom(0.9, true)
  }

  updateZoom(factor, useTransition = false) {
    // Apply smooth scaling to button zooming
    const smoothFactor = Math.exp(Math.log(factor) * 0.5)  // Soften the zoom step
    const newScale = Math.min(Math.max(this.scale * smoothFactor, this.minScale), this.maxScale)

    if (Math.abs(newScale - this.scale) >= this.minScaleChange) {
      const rect = this.svgTarget.getBoundingClientRect()
      const centerX = rect.width / 2
      const centerY = rect.height / 2

      const point = this.svgTarget.createSVGPoint()
      point.x = centerX
      point.y = centerY
      const svgPoint = point.matrixTransform(this.contentTarget.getCTM().inverse())

      const scaleDiff = newScale - this.scale
      this.scale = newScale
      this.panX = this.panX - (svgPoint.x * scaleDiff)
      this.panY = this.panY - (svgPoint.y * scaleDiff)

      if (!useTransition) {
        this.contentTarget.style.transition = 'none'
      }

      this.applyTransform()

      if (!useTransition) {
        requestAnimationFrame(() => {
          this.contentTarget.style.transition = 'transform 0.1s ease-out'
        })
      }
    }
  }

    applyTransform() {
      this.contentTarget.setAttribute(
        'transform',
        `translate(${this.panX}, ${this.panY}) scale(${this.scale})`
      )
    }
}