import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "select", "options", "clearButton"]
  static values = {
    metrics: Array,
    fieldId: String
  }

  connect() {
    this.filterMetrics = this.filterMetrics.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    document.addEventListener("click", this.handleClickOutside)
    this.originalValue = this.inputTarget.value
    this.updateClearButtonVisibility()
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside)
  }

  updateClearButtonVisibility() {
    if (this.hasClearButtonTarget) {
      this.clearButtonTarget.classList.toggle('hidden', !this.inputTarget.value)
    }
  }

  clearSelection() {
    this.inputTarget.value = ''
    this.originalValue = ''
    this.selectTarget.value = ''

    const changeEvent = new Event('change', { bubbles: true })
    this.selectTarget.dispatchEvent(changeEvent)

    this.updateClearButtonVisibility()
  }

  filterMetrics() {
    const query = this.inputTarget.value.toLowerCase()
    const filteredMetrics = this.metricsValue.filter(([name, _, category, cba]) => {
      return name.toLowerCase().includes(query) ||
        (category && category.toLowerCase().includes(query)) ||
        (cba && cba.toLowerCase().includes(query))
    })

    this.renderOptions(filteredMetrics)
  }

  renderOptions(metrics) {
    if (metrics.length === 0) {
      this.optionsTarget.innerHTML = `
        <li class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900">
          No metrics found
        </li>
      `
      return
    }

    // Group metrics by CBA and MetricCategory
    const grouped = metrics.reduce((acc, metric) => {
      const [name, id, category, cba] = metric
      if (!acc[cba]) {
        acc[cba] = {}
      }
      if (!acc[cba][category]) {
        acc[cba][category] = []
      }
      acc[cba][category].push([name, id])
      return acc
    }, {})

    this.optionsTarget.innerHTML = `
      ${Object.entries(grouped).map(([cba, categories], cbaIndex) => `
        ${cbaIndex > 0 ? '<li class="border-t border-gray-200"></li>' : ''}
        <li class="relative mt-0 py-2.5 pl-3 pr-9 text-xs font-semibold text-gray-500 uppercase tracking-wider bg-gray-100 border-gray-200 border-b">
          ${cba}
        </li>
        ${Object.entries(categories).map(([category, metrics]) => `
          <li class="relative py-2 pl-3 pr-9 text-xs font-medium text-gray-700">
            ${category}
          </li>
          ${metrics.map(([name, id]) => `
            <li class="relative cursor-default select-none py-2 pl-6 pr-9 hover:bg-blue-600 hover:text-white group"
                role="option"
                data-action="click->dashboard-metric-combobox#selectMetric"
                data-name="${name}"
                data-value="${id}">
              <span class="block truncate">
                ${name}
              </span>
            </li>
          `).join('')}
        `).join('')}
      `).join('')}
    `
  }

  toggleOptions() {
    const isExpanded = this.inputTarget.getAttribute('aria-expanded') === 'true'
    if (isExpanded) {
      this.closeOptions()
    } else {
      this.openOptions()
    }
  }

  openOptions() {
    this.inputTarget.setAttribute('aria-expanded', 'true')
    this.optionsTarget.classList.remove('hidden')
    this.positionDropdown()
    this.originalValue = this.inputTarget.value
    this.inputTarget.value = ''
    this.filterMetrics()
    this.inputTarget.focus()
  }

  positionDropdown() {
    this.optionsTarget.classList.remove('bottom-full', 'mb-1', 'mt-1')
    const inputRect = this.inputTarget.getBoundingClientRect()
    const dropdownHeight = 240
    const viewportHeight = window.innerHeight
    const spaceBelow = viewportHeight - inputRect.bottom
    const spaceAbove = inputRect.top

    if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
      this.optionsTarget.classList.add('bottom-full', 'mb-1')
    } else {
      this.optionsTarget.classList.add('mt-1')
    }
  }

  closeOptions() {
    this.inputTarget.setAttribute('aria-expanded', 'false')
    this.optionsTarget.classList.add('hidden')
    if (!this.inputTarget.value.trim()) {
      this.inputTarget.value = this.originalValue
    }
    this.updateClearButtonVisibility()
  }

  selectMetric(event) {
    const { name, value } = event.currentTarget.dataset
    this.inputTarget.value = name
    this.originalValue = name
    this.selectTarget.value = value

    const changeEvent = new Event('change', { bubbles: true })
    this.selectTarget.dispatchEvent(changeEvent)

    this.closeOptions()
    this.updateClearButtonVisibility()
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.closeOptions()
    }
  }
}