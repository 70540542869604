import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "container"]
  static values = {
    wrapperSelector: String
  }

  connect() {
    this.wrapperSelectorValue = this.wrapperSelectorValue || "tr.nested-fields"
  }

  add(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.containerTarget.insertAdjacentHTML('beforeend', content)
  }

  remove(event) {
    event.preventDefault()
    const wrapper = event.target.closest(this.wrapperSelectorValue)
    
    // New records can be removed from the dom
    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove()
    // Existing records are hidden and marked for deletion
    } else {
      wrapper.style.display = "none"
      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = "1"
    }
  }
} 