import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["panel", "overlay", "profilePanel", "profileFrame"]
  static values = {
    currentNode: String
  }

  connect() {
    this.checkScreenSize()
    window.addEventListener('resize', () => this.checkScreenSize())
  }

  disconnect() {
    window.removeEventListener('resize', () => this.checkScreenSize())
  }

  checkScreenSize() {
    if (window.innerWidth >= 1024) { // lg breakpoint
      this.show()
    } else {
      this.hide()
    }
  }

  toggle() {
    if (this.panelTarget.classList.contains('-translate-x-full')) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.panelTarget.classList.remove('-translate-x-full')
    this.panelTarget.classList.remove('opacity-0')
    this.overlayTarget.classList.remove('pointer-events-none')
  }

  hide() {
    if (window.innerWidth < 1024) { // Only hide on mobile
      this.panelTarget.classList.add('-translate-x-full')
      this.panelTarget.classList.add('opacity-0')
      this.overlayTarget.classList.add('pointer-events-none')
    }
  }

  showLoadingState() {
    this.profileFrameTarget.innerHTML = `
      <div class="p-4 flex items-center justify-center">
        <div class="animate-pulse flex flex-col items-center space-y-2">
          <div class="animate-spin rounded-full h-10 w-10 border-b-2 border-primary-500"></div>
        </div>
      </div>
    `
  }

  toggleProfile(event) {
    const accountUserId = event.currentTarget.dataset.accountUserId
    const profileUrl = event.currentTarget.dataset.orgChartDrawerProfileUrl

    // Check if panel is closed or if clicking same node
    const isPanelClosed = this.profilePanelTarget.classList.contains('-translate-x-full')
    const isClickingSameNode = this.currentNodeValue === accountUserId

    if (isClickingSameNode && !isPanelClosed) {
      // If clicking same node and panel is open, close it
      this.closeProfile()
      this.currentNodeValue = ''
      return
    }

    // Show loading state only if panel is closed
    if (isPanelClosed) {
      this.showLoadingState()
    }

    // Update the frame src
    if (this.profileFrameTarget && profileUrl) {
      this.profileFrameTarget.src = profileUrl
      this.currentNodeValue = accountUserId
    }

    // Show the panel if it's hidden
    if (isPanelClosed) {
      this.showProfile()
    }
  }

  showProfile() {
    this.profilePanelTarget.classList.remove('-translate-x-full')
    this.profilePanelTarget.classList.remove('opacity-0')
  }

  hideProfile() {
    this.profilePanelTarget.classList.add('-translate-x-full')
    this.profilePanelTarget.classList.add('opacity-0')
  }

  closeProfile() {
    this.hideProfile()
    // Clear the frame src and content when closing
    setTimeout(() => {
      // Wait for transition to complete before clearing
      this.profileFrameTarget.src = ''
      this.profileFrameTarget.innerHTML = ''
      this.currentNodeValue = ''
    }, 300)
  }
}