import { Controller } from "@hotwired/stimulus";
import { Modal } from "flowbite";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static targets = ["embedUrl", "iframeCode", "form", "result", "copyButton"];
  static values = { 
    type: String,
    url: String,
    modalTarget: String
  };
  
  connect() {
    // Bind closeModal method to this controller instance
    this.boundCloseModal = this.closeModal.bind(this);
    
    // If a type value is provided, show the appropriate view
    if (this.hasTypeValue && this.typeValue === "share") {
      this.showShareView();
    }
    
    // Initialize modal if we have a modalTarget value
    if (this.hasModalTargetValue) {
      this.initializeModal();
    }
  }
  
  // Initialize the modal and connect close buttons
  initializeModal() {
    const modalElement = document.getElementById(this.modalTargetValue);
    if (!modalElement) return;
    
    // Store modal instance
    this.modal = new Modal(modalElement, {
      backdrop: 'static',
      closable: true
    });
    
    // Connect close buttons
    const closeButtons = modalElement.querySelectorAll(`[data-modal-hide="${this.modalTargetValue}"]`);
    closeButtons.forEach(button => {
      button.addEventListener('click', this.boundCloseModal);
    });
  }
  
  // Close the modal
  closeModal() {
    if (this.modal) {
      this.modal.hide();
    }
  }
  
  // Explicit action for use with data-action
  close(event) {
    if (event) event.preventDefault();
    this.closeModal();
  }
  
  // New method to load content via fetch instead of a link click
  async loadContent(event) {
    if (event) event.preventDefault();
    
    if (!this.hasUrlValue) {
      console.error("No URL provided for embed controller");
      return;
    }
    
    // Show modal if modalTarget is provided
    if (this.hasModalTargetValue && this.modal) {
      this.modal.show();
    } else if (this.hasModalTargetValue) {
      // Initialize modal if not already done
      this.initializeModal();
      if (this.modal) this.modal.show();
    }
    
    try {
      const response = await fetch(this.urlValue, {
        headers: {
          "Accept": "text/vnd.turbo-stream.html",
          "X-Requested-With": "XMLHttpRequest"
        }
      });
      
      if (!response.ok) throw new Error(`Server responded with ${response.status}`);
      
      const html = await response.text();
      Turbo.renderStreamMessage(html);
    } catch (error) {
      console.error("Error loading embed content:", error);
    }
  }
  
  async create(event) {
    event.preventDefault();
    
    const form = this.formTarget;
    const formData = new FormData(form);
    
    // Determine the embed type
    const embedType = this.hasTypeValue && this.typeValue === "share" ? "share" : "embed";
    
    // Update the form data with the correct embed type
    if (embedType === "share") {
      // Remove any existing embed_type
      if (formData.has('embed_type')) {
        formData.delete('embed_type');
      }
      // Add the share embed_type
      formData.append('embed_type', 'share');
    }
    
    const response = await fetch("/embeds", {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
        "Accept": "application/json"
      },
      body: formData
    });
    
    const data = await response.json();
    
    if (response.ok) {
      if (embedType === "share") {
        this.embedUrlTarget.value = data.share_url || data.embed_url;
        this.resultTarget.classList.remove("hidden");
        this.showShareResult();
      } else {
        this.iframeCodeTarget.value = data.iframe_code;
        this.resultTarget.classList.remove("hidden");
        this.showEmbedResult();
      }
    } else {
      alert(`Error creating embed: ${data.error}`);
    }
  }
  
  copy(event) {
    const button = event.currentTarget;
    const targetId = button.dataset.target;
    const inputElement = document.getElementById(targetId);
    
    inputElement.select();
    document.execCommand("copy");
    
    // Show copied feedback
    const originalText = button.textContent;
    button.textContent = "Copied!";
    
    setTimeout(() => {
      button.textContent = originalText;
    }, 2000);
  }
  
  showShareView() {
    // Hide embed elements
    document.querySelectorAll('[data-embed-type="embed"]').forEach(el => el.classList.add('hidden'));
    // Show share elements
    document.querySelectorAll('[data-embed-type="share"]').forEach(el => el.classList.remove('hidden'));
  }
  
  showEmbedResult() {
    document.querySelectorAll('[data-embed-type="embed"]').forEach(el => {
      if (el.closest('[data-embed-target="result"]')) {
        el.classList.remove('hidden');
      }
    });
    document.querySelectorAll('[data-embed-type="share"]').forEach(el => {
      if (el.closest('[data-embed-target="result"]')) {
        el.classList.add('hidden');
      }
    });
  }
  
  showShareResult() {
    document.querySelectorAll('[data-embed-type="embed"]').forEach(el => {
      if (el.closest('[data-embed-target="result"]')) {
        el.classList.add('hidden');
      }
    });
    document.querySelectorAll('[data-embed-type="share"]').forEach(el => {
      if (el.closest('[data-embed-target="result"]')) {
        el.classList.remove('hidden');
      }
    });
  }
  
  disconnect() {
    // Clean up modal event listeners when controller is disconnected
    if (this.hasModalTargetValue && this.modal) {
      const modalElement = document.getElementById(this.modalTargetValue);
      if (modalElement) {
        const closeButtons = modalElement.querySelectorAll(`[data-modal-hide="${this.modalTargetValue}"]`);
        closeButtons.forEach(button => {
          button.removeEventListener('click', this.boundCloseModal);
        });
      }
      this.modal = null;
    }
  }
} 