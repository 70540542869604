import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    if (this.hasSelectTarget) {
      this.selectTarget.addEventListener('change', this.navigate.bind(this))
    }
  }

  navigate(event) {
    const path = event.target.value
    if (path) {
      window.location.href = path
    }
  }
} 