import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "icon", "badge"]

  connect() {
    // Initialize expanded state from localStorage if available
    const expanded = localStorage.getItem(`cba-${this.element.dataset.cbaId}-expanded`)
    if (expanded === "false") {
      this.collapse(false)
    } else {
      this.updateBadgeState(true)
    }
  }

  toggle() {
    if (this.contentTargets[0].classList.contains("hidden")) {
      this.expand()
    } else {
      this.collapse()
    }
  }

  expand(animate = false) {
    // Return early if already expanded
    if (this.contentTargets[0] && !this.contentTargets[0].classList.contains("hidden")) {
      return
    }

    this.contentTargets.forEach(content => {
      const targetHeight = content.scrollHeight + "px";
      content.style.setProperty("--target-height", targetHeight);

      content.classList.remove("hidden");
      if (animate) {
        content.classList.remove("animate-collapse");
        content.classList.add("animate-expand");
      } else {
        content.style.height = targetHeight;
        content.style.opacity = "1";
      }
    })

    this.iconTarget.classList.remove("rotate-180")

    localStorage.setItem(`cba-${this.element.dataset.cbaId}-expanded`, "true")
    this.updateBadgeState(true)
  }

  collapse(animate = false) {
    this.contentTargets.forEach(content => {
      const targetHeight = content.scrollHeight + "px";
      content.style.setProperty("--target-height", targetHeight);

      if (animate) {
        content.classList.remove("animate-expand");
        content.classList.add("animate-collapse");

        // Add hidden class after animation
        content.addEventListener(
          "animationend",
          () => {
            content.classList.add("hidden");
            content.style.height = null; // Reset height
          },
          { once: true }
        );
      } else {
        content.classList.add("hidden");
        content.style.height = "0";
        content.style.opacity = "0";
      }
    })
    this.iconTarget.classList.add("rotate-180")
    localStorage.setItem(`cba-${this.element.dataset.cbaId}-expanded`, "false")
    this.updateBadgeState(false)
  }

  updateBadgeState(expanded) {
    if (!this.hasBadgeTarget) return

    const badge = this.badgeTarget
    const indicator = badge.querySelector('span:first-child')
    const text = badge.querySelector('span:last-child')

    if (expanded) {
      badge.classList.remove('bg-orange-50', 'text-orange-700', 'ring-1', 'ring-orange-600/10')
      badge.classList.add('bg-green-50', 'text-green-700', 'ring-1', 'ring-green-600/10')
      indicator.classList.remove('bg-orange-400')
      indicator.classList.add('bg-green-400')
      text.textContent = 'Showing All Metrics'
    } else {
      badge.classList.remove('bg-green-50', 'text-green-700', 'ring-1', 'ring-green-600/10')
      badge.classList.add('bg-orange-50', 'text-orange-700', 'ring-1', 'ring-orange-600/10')
      indicator.classList.remove('bg-green-400')
      indicator.classList.add('bg-orange-400')
      text.textContent = 'North Star Only'
    }
  }
}