import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "categoryToggle"]

  connect() {
    // Build dependency relationships
    this.dependencyMap = new Map()
    this.dependentMap = new Map()

    // Build dependency relationships
    this.checkboxTargets.forEach(checkbox => {
      const metricId = checkbox.dataset.metricId
      const dependencies = (checkbox.dataset.dependencies || "").split(",").filter(Boolean)

      if (dependencies.length > 0) {
        this.dependencyMap.set(metricId, dependencies)

        // Build reverse mapping for dependents
        dependencies.forEach(depId => {
          if (!this.dependentMap.has(depId)) {
            this.dependentMap.set(depId, new Set())
          }
          this.dependentMap.get(depId).add(metricId)
        })
      }
    })

    // Initialize category toggles based on current checkbox states
    this.categoryToggleTargets.forEach(toggle => {
      this.updateCategoryToggleState(toggle)
    })

    // Add click event listener to the label containing the checkbox
    this.categoryToggleTargets.forEach(toggle => {
      const label = toggle.closest('label')
      if (label) {
        label.addEventListener('click', (event) => {
          // If the click was on the label but not on the checkbox itself
          if (event.target !== toggle) {
            event.preventDefault() // Prevent default label behavior
            toggle.checked = !toggle.checked // Toggle the checkbox
            this.toggleCategory({ target: toggle }) // Trigger the toggle event
          }
        })
      }
    })
  }

  toggleCategory(event) {
    const toggle = event.target
    const categoryId = toggle.dataset.categoryId
    const isChecked = toggle.checked

    // Find all checkboxes in this category that aren't disabled
    const categoryCheckboxes = this.checkboxTargets.filter(checkbox =>
      checkbox.dataset.categoryId === categoryId && !checkbox.disabled
    )

    // Check/uncheck all checkboxes in the category
    categoryCheckboxes.forEach(checkbox => {
      if (checkbox.checked !== isChecked) {
        checkbox.checked = isChecked
        if (isChecked) {
          this.handleDependencies(checkbox)
        } else {
          this.handleUncheck(checkbox)
        }
      }
    })

    // Update all category toggles to maintain consistency
    this.categoryToggleTargets.forEach(toggle => {
      this.updateCategoryToggleState(toggle)
    })
  }

  handleMetricSelection(event) {
    const checkbox = event.target
    if (checkbox.checked) {
      this.handleDependencies(checkbox)
    } else {
      this.handleUncheck(checkbox)
    }

    // Update all category toggles to maintain consistency
    this.categoryToggleTargets.forEach(toggle => {
      this.updateCategoryToggleState(toggle)
    })
  }

  handleDependencies(checkbox) {
    const dependencies = checkbox.dataset.dependencies
    if (!dependencies) return

    const dependencyIds = dependencies.split(",")
    if (checkbox.checked) {
      // If checking a metric, also check its dependencies
      dependencyIds.forEach(id => {
        const dependentCheckbox = this.checkboxTargets.find(cb =>
          cb.dataset.metricId === id && !cb.disabled
        )
        if (dependentCheckbox && !dependentCheckbox.checked) {
          dependentCheckbox.checked = true
          this.handleDependencies(dependentCheckbox)
        }
      })
    }
  }

  handleUncheck(checkbox) {
    const metricId = checkbox.dataset.metricId
    // Find all metrics that depend on this one
    const dependents = this.dependentMap.get(metricId) || new Set()

    // Uncheck all dependent metrics
    dependents.forEach(depId => {
      const dependentCheckbox = this.findCheckboxByMetricId(depId)
      if (dependentCheckbox && !dependentCheckbox.disabled && dependentCheckbox.checked) {
        dependentCheckbox.checked = false
        // Recursively uncheck metrics that depend on this one
        this.handleUncheck(dependentCheckbox)
      }
    })
  }

  updateCategoryToggleState(toggle) {
    if (!toggle) return

    const categoryId = toggle.dataset.categoryId
    const categoryCheckboxes = this.checkboxTargets.filter(checkbox =>
      checkbox.dataset.categoryId === categoryId && !checkbox.disabled
    )

    if (categoryCheckboxes.length === 0) {
      toggle.disabled = true
      toggle.checked = false
      return
    }

    // Check if all enabled checkboxes in the category are checked
    const allChecked = categoryCheckboxes.every(checkbox => checkbox.checked)
    const anyChecked = categoryCheckboxes.some(checkbox => checkbox.checked)

    // Update the toggle state
    toggle.checked = allChecked
    toggle.indeterminate = anyChecked && !allChecked
  }

  findCheckboxByMetricId(metricId) {
    return this.checkboxTargets.find(checkbox =>
      checkbox.dataset.metricId === metricId
    )
  }
} 