import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["weeklyBusinessReviewSection"]

  connect() {
    this.element.addEventListener('change', this.navigate.bind(this))
  }

  navigate(event) {
    const sectionId = event.target.value
    const section = document.querySelector(sectionId)

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }
} 