import { Controller } from "@hotwired/stimulus"
import { Sortable } from "@shopify/draggable"

export default class extends Controller {
  static targets = ["list"]

  connect() {
    requestAnimationFrame(() => {
      this.initializeSortable()
    })
  }

  initializeSortable() {
    try {
      this.sortable = new Sortable(this.listTarget, {
        draggable: '.metric-category-item',
        handle: '.metric-handle',
        mirror: {
          appendTo: this.listTarget,
          constrainDimensions: true,
          xAxis: false
        },
        classes: {
          'source:dragging': 'opacity-50',
          'mirror': ['bg-white', 'shadow-lg', 'rounded-lg']
        }
      })

      this.sortable.on('sortable:stop', (event) => {
        requestAnimationFrame(() => {
          const positions = Array.from(event.newContainer.children)
            .filter(el => el.classList.contains('metric-category-item'))
            .map((item, index) => ({
              id: item.dataset.categoryId,
              position: index
            }))

          this.updatePositions(positions, event.newContainer.dataset.coreBusinessAreaId)
        })
      })
    } catch (error) {
      console.error('Error initializing Sortable:', error)
    }
  }

  disconnect() {
    if (this.sortable && this.sortable.destroy) {
      this.sortable.destroy()
    }
  }

  async updatePositions(positions, coreBusinessAreaId) {
    try {
      const response = await fetch('/settings/metric_categories/reorder', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          positions,
          core_business_area_id: coreBusinessAreaId
        })
      })

      const data = await response.json()

      if (!response.ok || !data.success) {
        throw new Error('Failed to update positions')
      }
    } catch (error) {
      console.error('Error updating positions:', error)
    }
  }
} 