import { Controller } from "@hotwired/stimulus"
import { Sortable } from "@shopify/draggable"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.sortable = new Sortable(this.containerTarget, {
      draggable: 'tr',
      handle: '[data-sort-handle]',
      mirror: {
        constrainDimensions: true,
      }
    })

    this.sortable.on('sortable:stop', () => {
      requestAnimationFrame(() => {
        this.updatePositions()
      })
    })
    
    // Initialize positions on connect
    this.updatePositions()

    // Listen for new metrics being added
    document.addEventListener('metric-fields:add', this.handleNewMetric.bind(this))
  }

  disconnect() {
    this.sortable.destroy()
    document.removeEventListener('metric-fields:add', this.handleNewMetric.bind(this))
  }

  handleNewMetric(event) {
    // Give the DOM time to update with the new row
    requestAnimationFrame(() => {
      this.updatePositions()
    })
  }

  updatePositions() {
    const visibleRows = Array.from(this.containerTarget.querySelectorAll('tr.nested-fields'))
      .filter(row => {
        return row.style.display !== 'none' && !row.classList.contains('template')
      })
    
    // Force a reflow to ensure all elements are in their final positions
    this.containerTarget.offsetHeight

    visibleRows.forEach((row, index) => {
      const positionInput = row.querySelector('input[name$="[position]"]')
      const positionDisplay = row.querySelector('[data-position-display]')
      const newPosition = index + 1
      
      if (positionInput) {
        positionInput.value = newPosition
      }
      
      if (positionDisplay) {
        positionDisplay.textContent = newPosition
      }
    })
  }
} 